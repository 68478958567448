@font-face {
  font-family: 'fontello';
  src: url('../../fonts/fontello.eot?48529630');
  src: url('../../fonts/fontello.eot?48529630#iefix') format('embedded-opentype'),
  url('../../fonts/fontello.woff2?48529630') format('woff2'),
  url('../../fonts/fontello.woff?48529630') format('woff'),
  url('../../fonts/fontello.ttf?48529630') format('truetype'),
  url('../../fonts/fontello.svg?48529630#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent scss, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-help:before { content: '\e800'; } /* '' */
.icon-trustwallet:before { content: '\e801'; } /* '' */
.icon-check:before { content: '\e802'; } /* '' */
.icon-plus:before { content: '\e803'; } /* '' */
.icon-info:before { content: '\e804'; } /* '' */
.icon-arrow-menu:before { content: '\e805'; } /* '' */
.icon-copy:before { content: '\e806'; } /* '' */
.icon-share:before { content: '\e807'; } /* '' */
.icon-drop:before { content: '\e808'; } /* '' */
.icon-menu:before { content: '\e809'; } /* '' */
.icon-exit:before { content: '\e80a'; } /* '' */
.icon-close:before { content: '\e80b'; } /* '' */
.icon-dashboard:before { content: '\e80c'; } /* '' */
.icon-farming:before { content: '\e80d'; } /* '' */
.icon-games:before { content: '\e80e'; } /* '' */
.icon-investment:before { content: '\e80f'; } /* '' */
.icon-quests:before { content: '\e810'; } /* '' */
.icon-top-arrow:before { content: '\e811'; } /* '' */
.icon-exchange:before { content: '\e812'; } /* '' */
.icon-exchange2:before { content: '\e813'; } /* '' */
.icon-referral:before { content: '\e814'; } /* '' */
.icon-copy2:before { content: '\e815'; } /* '' */
.icon-copy3:before { content: '\e816'; } /* '' */
.icon-copy4:before { content: '\e817'; } /* '' */
.icon-farming2:before { content: '\e818'; } /* '' */
.icon-additional:before { content: '\e819'; } /* '' */
.icon-deposits:before { content: '\e81a'; } /* '' */
.icon-history:before { content: '\e81b'; } /* '' */
.icon-staking:before { content: '\e81c'; } /* '' */
.icon-present:before { content: '\e81d'; } /* '' */
.icon-deposits2:before { content: '\e81e'; } /* '' */
.icon-info2:before { content: '\e81f'; } /* '' */