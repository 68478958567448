.modal {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;

  &.active {
    opacity: 1;
    pointer-events: all;
    display: block;
  }
}
.icon-liquidity {
  content: url("../images/liquidity.svg");
}
// #info{
//     content: url('../images/help-info.svg')
// }
.popup-replenishment {
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  display: block;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  .close {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
  .popup-content {
    top: 50%;
    left: 50%;
    width: 320px;
    position: absolute;
    border-radius: 25px;
    padding: 0 10px 5px;
    background: #ffffff;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(112, 54, 182, 0.1);
    .close-popup {
      top: 14px;
      right: 12px;
      z-index: 3;
      cursor: pointer;
      position: absolute;
      &:hover {
        i {
          transform: rotate(360deg);
        }
      }
      i {
        color: #fff;
        display: block;
        font-size: 18px;
        transition: all 0.5s;
      }
    }
    .title {
      margin: 0 -16px;
      padding: 15px 10px;
      border-radius: 15px;
      background: #cd2576;
      transform: translate(0, -1px);
      h4 {
        color: #fff;
        font-size: 16px;
        display: flex;
        font-weight: 600;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
          font-size: 14px;
          margin-right: 7px;
        }
      }
    }
    .balance {
      display: flex;
      padding: 12px 0;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(112, 54, 182, 0.1);
      div {
        &:last-child {
          text-align: right;
        }
        span {
          display: block;
          font-size: 12px;
          color: #9999a5;
          line-height: 15px;
          margin-bottom: 5px;
        }
        strong {
          font-size: 16px;
          color: #2b1a4f;
          font-weight: 600;
          line-height: 20px;
        }
        a {
          width: auto;
          border: none;
          display: flex;
          font-size: 14px;
          color: #7036b6;
          font-weight: 500;
          line-height: 20px;
          align-items: center;
          justify-content: flex-end;
          background: transparent;
          i {
            display: block;
            font-size: 14px;
            color: #7036b6;
            margin-right: 6px;
          }
        }
      }
    }
    .replenishment-content {
      padding-top: 20px;
      p {
        font-size: 12px;
        color: #9999a5;
        line-height: 18px;
        text-align: center;
        font-weight: normal;
        margin-bottom: 15px;
      }
      .commission {
        p {
          display: flex;
          font-size: 10px;
          color: #9999a5;
          line-height: 16px;
          margin-bottom: 8px;
          align-items: center;
          justify-content: center;
          i {
            font-size: 12px;
            margin-left: 5px;
            color: rgba(112, 54, 182, 0.6);
          }
        }
        .commission-link {
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          justify-content: space-between;
          li {
            width: 48%;
            a {
              width: 100%;
              height: 39px;
              display: flex;
              font-size: 14px;
              color: #7036b6;
              line-height: 17px;
              font-weight: normal;
              border-radius: 15px;
              align-items: center;
              justify-content: center;
              background: rgba(112, 54, 182, 0.1);
              border: 1px solid rgba(112, 54, 182, 0.2);
              transition: all 0.4s;
              &:hover {
                transform: translate(0, -2px);
              }
              strong {
                font-weight: 600;
              }
            }
          }
          li.active {
            a {
              color: #ffffff;
              background: #7036b6;
              border: 1px solid rgba(112, 54, 182, 0.2);
            }
          }
        }
      }
      form {
        label {
          display: block;
          font-size: 12px;
          text-align: center;
          color: #7036b6;
          opacity: 0.6;
          line-height: 15px;
          margin-bottom: 5px;
          font-weight: normal;
        }
        .inputs {
          position: relative;
          margin-bottom: 7px;
          padding-bottom: 14px;
          border-bottom: 1px solid rgba(112, 54, 182, 0.1);
          .input {
            display: flex;
            margin-bottom: 19px;
            border-radius: 15px;
            align-items: center;
            position: relative;
            background: #f1ebf8;
            justify-content: space-between;
            &:last-child {
              margin-bottom: 0;
              border-radius: 15px 15px 0 0;
              input {
                margin-bottom: 0;
              }
            }
            div {
              width: 100%;
              input {
                width: 100%;
                height: 56px;
                border: none;
                font-size: 14px;
                color: #7036b6;
                padding: 0 14px;
                font-weight: 600;
                line-height: 17px;
                text-align: center;
                background: transparent;
                font-family: "Montserrat", sans-serif;
              }
              input::placeholder {
                color: #9999a5;
              }
            }
            strong {
              top: 50%;
              right: 14px;
              font-size: 14px;
              font-weight: 600;
              color: #7036b6;
              line-height: 17px;
              position: absolute;
              transform: translate(0, -50%);
            }
          }
        }
        .days {
          display: flex;
          align-items: center;
          background: #e3d9f2;
          border-radius: 0 0 15px 15px;
          justify-content: space-between;
          li {
            width: 25%;
            opacity: 0.8;
            display: flex;
            cursor: pointer;
            font-size: 12px;
            color: #cd2576;
            padding: 6px 5px;
            font-weight: 600;
            line-height: 15px;
            position: relative;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            &::after {
              content: "";
              top: 50%;
              width: 1px;
              right: -1px;
              height: 16px;
              opacity: 0.2;
              position: absolute;
              background: #cd2576;
              transform: translate(0, -50%);
            }
            span {
              display: block;
              text-align: center;
              font-weight: 600;
              font-size: 14px;
              line-height: 12px;
              span {
                font-size: 10px;
                line-height: 12px;
              }
            }
            &:first-child {
              border-radius: 0 0 0 15px;
            }
            &:last-child {
              border-radius: 0 0 15px 0;
              &::after {
                display: none;
              }
            }
            &:hover {
              opacity: 1;
              .present-info {
                display: block;
              }
            }
            i {
              font-size: 16px;
              margin-left: 5px;
            }
            .present-info {
              top: 27px;
              left: 50%;
              z-index: 2;
              width: 135px;
              padding: 15px;
              display: none;
              text-align: center;
              position: absolute;
              border-radius: 10px;
              background: #cd2576;
              box-shadow: 0px 12px 20px rgba(205, 37, 118, 0.4);
              transform: translate(-50%, 0);
              &::after {
                content: "";
                top: -7px;
                left: 50%;
                z-index: -1;
                width: 14px;
                height: 14px;
                position: absolute;
                background: #cd2576;
                transform: translate(-50%, 0) rotate(45deg);
              }
              strong {
                font-size: 12px;
                color: #f8f5fb;
                font-weight: 600;
                line-height: 15px;
              }
              p {
                font-size: 11px;
                color: #ffffff;
                margin-bottom: 0;
                line-height: 16px;
                font-weight: normal;
              }
            }
          }
          li.active {
            opacity: 1;
            color: #fff;
            background: #cd2576;
          }
        }
        button {
          width: 100%;
          height: 44px;
          border: none;
          font-size: 14px;
          display: flex;
          color: #7036b6;
          font-weight: 500;
          line-height: 17px;
          align-items: center;
          justify-content: center;
          background: transparent;
          i {
            display: block;
            font-size: 14px;
            color: #7036b6;
            margin-right: 6px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .popup-content {
      width: 487px;
      padding: 0 40px 15px;
      .close-popup {
        top: 2px;
        right: -47px;
      }
      .title {
        margin: 0 -50px;
        padding: 23px 10px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 18px;
            margin-right: 8px;
          }
        }
      }
      .balance {
        padding: 20px 0;
        div {
          span {
            font-size: 14px;
            line-height: 18px;
          }
          strong {
            font-size: 20px;
            line-height: 24px;
          }
          a {
            font-size: 20px;
            line-height: 24px;
            i {
              font-size: 18px;
            }
          }
        }
      }
      .replenishment-content {
        padding-top: 20px;
        p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px;
        }
        .commission {
          margin-bottom: 20px;
          p {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 15px;
          }
        }
        form {
          label {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 8px;
          }
          .inputs {
            margin-bottom: 10px;
            padding-bottom: 20px;
            .input {
              margin-bottom: 30px;
              div {
                input {
                  height: 62px;
                  font-size: 18px;
                  padding: 0 20px;
                  line-height: 20px;
                }
              }
            }
          }
          .days {
            li {
              padding: 10px 5px;
              span {
                font-size: 18px;
                line-height: 14px;
              }
              i {
                font-size: 18px;
              }
              .present-info {
                top: 50px;
                width: 185px;
              }
            }
          }
          button {
            height: 50px;
            font-size: 18px;
            line-height: 22px;
            i {
              font-size: 18px;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .popup-content {
      .close-popup {
        top: 1px;
        i {
          font-size: 20px;
        }
      }
      .title {
        padding: 24px 30px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 20px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
.popup-successfully {
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  display: none;
  height: 100vh;
  position: fixed;
  .close {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
  .popup-content {
    top: 50%;
    left: 50%;
    width: 320px;
    position: absolute;
    padding: 25px 20px;
    text-align: center;
    border-radius: 15px;
    background: #f1ebf8;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(112, 54, 182, 0.1);
    .close-popup {
      top: 10px;
      z-index: 3;
      right: 10px;
      cursor: pointer;
      position: absolute;
      &:hover {
        i {
          transform: rotate(360deg);
        }
      }
      i {
        display: block;
        font-size: 18px;
        color: #cd2576;
        transition: all 0.5s;
      }
    }
    h5 {
      font-size: 16px;
      font-weight: 600;
      color: #2b1a4f;
      line-height: 22px;
      margin-bottom: 10px;
      i {
        display: block;
        font-size: 34px;
        color: #7036b6;
        margin: 0 auto 15px;
      }
    }
    p {
      font-size: 12px;
      color: #2b1a4f;
      line-height: 18px;
      font-weight: normal;
      a {
        font-size: 12px;
        color: #2b1a4f;
        font-weight: 600;
        line-height: 18px;
        text-decoration: underline;
      }
    }
  }
  @media (min-width: 768px) {
    .popup-content {
      width: 434px;
      padding: 30px 40px;
      h5 {
        font-size: 18px;
        padding: 0 50px;
        line-height: 24px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.popup-output {
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  display: none;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  .close {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
  .popup-content {
    top: 50%;
    left: 50%;
    width: 320px;
    position: absolute;
    border-radius: 25px;
    padding: 0 15px 25px;
    background: #ffffff;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(112, 54, 182, 0.1);
    .close-popup {
      top: 17px;
      right: 12px;
      z-index: 3;
      cursor: pointer;
      position: absolute;
      &:hover {
        i {
          transform: rotate(360deg);
        }
      }
      i {
        color: #fff;
        display: block;
        font-size: 18px;
        transition: all 0.5s;
      }
    }
    .title {
      margin: 0 -26px;
      padding: 17px 10px;
      border-radius: 15px;
      background: #cd2576;
      transform: translate(0, -1px);
      h4 {
        font-size: 18px;
        display: flex;
        color: #fff;
        font-weight: 600;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
          font-size: 18px;
          margin-right: 8px;
        }
      }
    }
    p {
      font-size: 14px;
      color: #9999a5;
      line-height: 21px;
      text-align: center;
      font-weight: normal;
      margin: 20px 0 15px;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 48%;
        a {
          width: 100%;
          height: 47px;
          font-size: 14px;
          display: flex;
          font-weight: 500;
          line-height: 17px;
          font-style: normal;
          border-radius: 15px;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
        }
        .cancellation-link {
          color: #7036b6;
          background: #f1ebf8;
          &:hover {
            color: #f1ebf8;
            background: #7036b6;
          }
        }
        .output-link {
          color: #ffffff;
          background: #cd2576;
          &:hover {
            color: #cd2576;
            background: #f1ebf8;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .popup-content {
      width: 467px;
      padding: 0 30px 40px;
      .close-popup {
        top: 2px;
        right: -47px;
      }
      .title {
        margin: 0 -41px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin: 25px 0 15px;
      }
      ul {
        li {
          a {
            height: 62px;
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .popup-content {
      .close-popup {
        top: 1px;
        i {
          font-size: 20px;
        }
      }
      .title {
        padding: 24px 30px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 20px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}

.popup-refill {
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  .close {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
  .popup-content {
    top: 50%;
    left: 50%;
    width: 320px;
    position: absolute;
    border-radius: 25px;
    padding: 0 10px 30px;
    background: #ffffff;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(112, 54, 182, 0.1);
    .close-popup {
      top: 17px;
      right: 12px;
      z-index: 3;
      cursor: pointer;
      position: absolute;
      &:hover {
        i {
          transform: rotate(360deg);
        }
      }
      i {
        color: #fff;
        display: block;
        font-size: 18px;
        transition: all 0.5s;
      }
    }
    .title {
      margin: 0 -16px;
      padding: 17px 10px;
      border-radius: 15px;
      background: #cd2576;
      transform: translate(0, -1px);
      h4 {
        font-size: 18px;
        display: flex;
        color: #fff;
        font-weight: 600;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
          font-size: 18px;
          margin-right: 8px;
        }
      }
    }
    .qr {
      text-align: center;
      padding: 19px 27px 8px;
      p {
        font-size: 14px;
        color: #9999a5;
        line-height: 21px;
        font-weight: normal;
      }
      svg {
        margin: 12px 0 27px;
      }
    }
    .referral-link {
      text-align: center;
      border-radius: 15px;
      background: #f1ebf8;
      padding: 9px 10px 4px;
      border: 1px solid rgba(112, 54, 182, 0.1);
      span {
        display: block;
        padding: 0 35px;
        font-size: 12px;
        color: #9999a5;
        line-height: 15px;
        margin-bottom: 6px;
        font-weight: normal;
      }
      .link {
        width: 100%;
        padding: 0 35px;
        position: relative;
        display: inline-block;
        p {
          font-size: 14px;
          font-weight: 600;
          color: #2b1a4f;
          overflow: hidden;
          line-height: 17px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .share {
          display: none !important;
          left: 3px;
          bottom: 4px;
          position: absolute;
          i {
            font-size: 26px;
            color: #7036b6;
          }
        }
        .copy {
          display: block !important;
          right: 0;
          bottom: 3px;
          position: absolute;
          i {
            font-size: 28px;
            color: #7036b6;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .popup-content {
      width: 467px;
      padding: 0 59px 40px;
      .close-popup {
        top: 2px;
        right: -47px;
      }
      .title {
        margin: 0 -70px;
      }
      .qr {
        padding: 32px 0 15px;
        p {
          font-size: 16px;
          line-height: 22px;
        }
        svg {
          width: 70%;
          margin: 10px 0 38px;
        }
      }
      .referral-link {
        padding: 14px 30px 10px;
        span {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 9px;
        }
        .link {
          text-align: left;
          padding: 0 25px 0 0;
          .share {
            display: none;
          }
          p {
            font-size: 18px;
            line-height: 22px;
          }
          .copy {
            right: 0;
            bottom: 0;
            i {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .popup-content {
      .close-popup {
        top: 1px;
        i {
          font-size: 20px;
        }
      }
      .title {
        padding: 24px 30px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 20px;
            margin-right: 6px;
          }
        }
      }
      .qr {
        padding: 40px 0 15px;
        svg {
          width: 88%;
          margin: 14px 0 42px;
        }
      }
    }
  }
}

.popup-withdraw {
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  .close {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
  .popup-content {
    top: 50%;
    left: 50%;
    width: 320px;
    position: absolute;
    border-radius: 25px;
    padding: 0 10px 5px;
    background: #ffffff;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(112, 54, 182, 0.1);
    .close-popup {
      top: 14px;
      right: 12px;
      z-index: 3;
      cursor: pointer;
      position: absolute;
      &:hover {
        i {
          transform: rotate(360deg);
        }
      }
      i {
        color: #fff;
        display: block;
        font-size: 18px;
        transition: all 0.5s;
      }
    }
    .title {
      margin: 0 -16px;
      padding: 15px 10px;
      border-radius: 15px;
      background: #cd2576;
      transform: translate(0, -1px);
      h4 {
        color: #fff;
        font-size: 16px;
        display: flex;
        font-weight: 600;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
          font-size: 14px;
          margin-right: 7px;
        }
      }
    }
    .min-sum {
      padding: 10px;
      font-size: 12px;
      display: flex;
      color: #cd2576;
      line-height: 17px;
      align-items: center;
      font-weight: normal;
      margin: -1px -10px 0;
      background: #fae9f1;
      justify-content: center;
      border-radius: 0 0 15px 15px;
      i {
        font-size: 14px;
        color: #cd2576;
        margin-right: 5px;
        display: inline-block;
      }
    }
    .withdraw-content {
      padding-top: 14px;
      p {
        font-size: 12px;
        color: #9999a5;
        line-height: 18px;
        text-align: center;
        font-weight: normal;
        margin-bottom: 15px;
      }
      .commission {
        p {
          display: flex;
          font-size: 10px;
          color: #9999a5;
          line-height: 16px;
          margin-bottom: 8px;
          align-items: center;
          justify-content: center;
          i {
            font-size: 12px;
            margin-left: 5px;
            color: rgba(112, 54, 182, 0.6);
          }
        }
        .commission-link {
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          justify-content: space-between;
          li {
            width: 48%;
            a {
              width: 100%;
              height: 39px;
              display: flex;
              font-size: 14px;
              color: #7036b6;
              line-height: 17px;
              font-weight: normal;
              border-radius: 15px;
              align-items: center;
              justify-content: center;
              background: rgba(112, 54, 182, 0.1);
              border: 1px solid rgba(112, 54, 182, 0.2);
              transition: all 0.4s;
              &:hover {
                transform: translate(0, -2px);
              }
              strong {
                font-weight: 600;
              }
            }
          }
          li.active {
            a {
              color: #ffffff;
              background: #7036b6;
              border: 1px solid rgba(112, 54, 182, 0.2);
            }
          }
        }
      }
      form {
        .inputs {
          position: relative;
          margin-bottom: 7px;
          padding-bottom: 14px;
          border-bottom: 1px solid rgba(112, 54, 182, 0.1);
          .input {
            display: flex;
            margin-bottom: 19px;
            border-radius: 15px;
            align-items: center;
            background: #f1ebf8;
            padding: 10px 14px 8px 14px;
            justify-content: space-between;
            &:last-child {
              margin-bottom: 0;
            }
            div {
              width: 75%;
              label {
                display: block;
                font-size: 12px;
                color: #7036b6;
                line-height: 15px;
                margin-bottom: 5px;
                font-weight: normal;
              }
              input {
                width: 100%;
                height: 20px;
                border: none;
                font-size: 14px;
                color: #7036b6;
                font-weight: 600;
                line-height: 17px;
                background: transparent;
                font-family: "Montserrat", sans-serif;
              }
              input::placeholder {
                color: #9999a5;
              }
            }
            strong {
              font-size: 14px;
              font-weight: 600;
              color: #7036b6;
              line-height: 17px;
            }
          }
        }
        button {
          width: 100%;
          height: 44px;
          border: none;
          font-size: 14px;
          display: flex;
          color: #7036b6;
          font-weight: 500;
          line-height: 17px;
          align-items: center;
          justify-content: center;
          background: transparent;
          i {
            display: block;
            font-size: 14px;
            color: #7036b6;
            margin-right: 6px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .popup-content {
      width: 467px;
      padding: 0 20px 12px;
      .close-popup {
        top: 2px;
        right: -47px;
      }
      .title {
        margin: 0 -30px;
        padding: 23px 10px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 18px;
            margin-right: 8px;
          }
        }
      }
      .min-sum {
        font-size: 14px;
        line-height: 17px;
        padding: 15px 10px;
        margin: -1px -20px 0;
      }
      .withdraw-content {
        padding-top: 20px;
        p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px;
        }
        .commission {
          margin-bottom: 20px;
          p {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 15px;
          }
        }
        form {
          .inputs {
            margin-bottom: 10px;
            padding-bottom: 20px;
            .input {
              margin-bottom: 30px;
              padding: 14px 20px 10px;
              div {
                label {
                  font-size: 14px;
                  line-height: 18px;
                  margin-bottom: 0;
                }
                input {
                  height: 30px;
                  font-size: 18px;
                  line-height: 20px;
                }
              }
            }
          }
          button {
            height: 50px;
            font-size: 18px;
            line-height: 22px;
            i {
              font-size: 18px;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .popup-content {
      .close-popup {
        top: 1px;
        i {
          font-size: 20px;
        }
      }
      .title {
        padding: 24px 30px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 20px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}

.popup-transaction {
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  display: none;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  .close {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
  .popup-content {
    top: 50%;
    left: 50%;
    width: 320px;
    position: absolute;
    border-radius: 25px;
    padding: 0 15px 25px;
    background: #ffffff;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(112, 54, 182, 0.1);
    .close-popup {
      top: 17px;
      right: 12px;
      z-index: 3;
      cursor: pointer;
      position: absolute;
      &:hover {
        i {
          transform: rotate(360deg);
        }
      }
      i {
        color: #fff;
        display: block;
        font-size: 18px;
        transition: all 0.5s;
      }
    }
    .title {
      margin: 0 -26px;
      padding: 17px 10px;
      border-radius: 15px;
      background: #cd2576;
      transform: translate(0, -1px);
      h4 {
        font-size: 18px;
        display: flex;
        color: #fff;
        font-weight: 600;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
          font-size: 18px;
          margin-right: 8px;
        }
      }
    }
    p {
      font-size: 14px;
      color: #9999a5;
      line-height: 21px;
      text-align: center;
      font-weight: normal;
      margin: 20px 0 15px;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 48%;
        a {
          width: 100%;
          height: 40px;
          font-size: 14px;
          display: flex;
          font-weight: 500;
          line-height: 17px;
          font-style: normal;
          border-radius: 15px;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          &:hover {
            transform: translate(0, -5px);
          }
        }
        .cancel-link {
          color: #7036b6;
          background: rgba(112, 54, 182, 0.1);
          border: 1px solid rgba(112, 54, 182, 0.2);
        }
        .confirm-link {
          color: #ffffff;
          background: #7036b6;
          border: 1px solid rgba(112, 54, 182, 0.2);
        }
      }
    }
  }
  @media (min-width: 768px) {
    .popup-content {
      width: 467px;
      padding: 0 20px 40px;
      .close-popup {
        top: 2px;
        right: -47px;
      }
      .title {
        margin: 0 -41px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin: 38px 10px 28px;
      }
    }
  }
  @media (min-width: 1600px) {
    .popup-content {
      .close-popup {
        top: 1px;
        i {
          font-size: 20px;
        }
      }
      .title {
        padding: 24px 30px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 20px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}

.popup-withdraw-active {
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  display: none;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  .close {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
  .popup-content {
    top: 50%;
    left: 50%;
    width: 320px;
    position: absolute;
    border-radius: 25px;
    padding: 0 15px 25px;
    background: #ffffff;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(112, 54, 182, 0.1);
    .close-popup {
      top: 17px;
      right: 12px;
      z-index: 3;
      cursor: pointer;
      position: absolute;
      &:hover {
        i {
          transform: rotate(360deg);
        }
      }
      i {
        color: #fff;
        display: block;
        font-size: 18px;
        transition: all 0.5s;
      }
    }
    .title {
      margin: 0 -26px;
      padding: 17px 10px;
      border-radius: 15px;
      background: #cd2576;
      transform: translate(0, -1px);
      h4 {
        font-size: 18px;
        display: flex;
        color: #fff;
        font-weight: 600;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
          font-size: 18px;
          margin-right: 8px;
        }
      }
    }
    p {
      font-size: 14px;
      color: #9999a5;
      line-height: 21px;
      text-align: center;
      font-weight: normal;
      margin: 20px 0 15px;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 48%;
        a {
          width: 100%;
          height: 40px;
          font-size: 14px;
          display: flex;
          font-weight: 500;
          line-height: 17px;
          font-style: normal;
          border-radius: 15px;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          &:hover {
            transform: translate(0, -5px);
          }
        }
        .cancel-link {
          color: #7036b6;
          background: rgba(112, 54, 182, 0.1);
          border: 1px solid rgba(112, 54, 182, 0.2);
        }
        .confirm-link {
          color: #ffffff;
          background: #7036b6;
          border: 1px solid rgba(112, 54, 182, 0.2);
        }
      }
    }
  }
  @media (min-width: 768px) {
    .popup-content {
      width: 467px;
      padding: 0 20px 40px;
      .close-popup {
        top: 2px;
        right: -47px;
      }
      .title {
        margin: 0 -41px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin: 38px 50px 28px;
      }
    }
  }
  @media (min-width: 1600px) {
    .popup-content {
      .close-popup {
        top: 1px;
        i {
          font-size: 20px;
        }
      }
      .title {
        padding: 24px 30px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 20px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}

.platforma {
  padding: 20px;
  //min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  .platforma-nav-mobile-bg {
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    pointer-events: none;
    transition: opacity 0.5s;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
  .platforma-nav-mobile {
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    position: fixed;
    transform: translate(110%, 0);
    transition: all 0.5s;
    .nav-mobile-content {
      top: 0;
      right: 0;
      z-index: 1;
      height: 100vh;
      max-height: 100vh;
      position: absolute;
      background: #ffffff;
      border-radius: 25px 0 0 25px;
      border: 1px solid rgba(112, 54, 182, 0.1);
      .wallet-mobile {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 19px 19px 40px 29px;
        .wallet {
          display: flex;
          margin-right: 28px;
          align-items: center;
          img {
            width: 42px;
          }
          .wallet-text {
            display: flex;
            margin-left: 11px;
            position: relative;
            padding-right: 40px;
            flex-direction: column;
            span {
              font-size: 12px;
              color: #7036b6;
              font-weight: 600;
              line-height: 17px;
              margin-bottom: 5px;
              display: inline-block;
            }
            strong {
              font-size: 14px;
              color: #2b1a4f;
              font-weight: 600;
              line-height: 20px;
              display: inline-block;
            }
            a {
              right: 0;
              top: 56%;
              font-size: 29px;
              color: #7036b6;
              position: absolute;
              transform: translate(0, -50%);
            }
          }
        }
        .close-nav {
          width: 44px;
          height: 44px;
          position: relative;
          border-radius: 10px;
          background: #cd2576;
          border: 1px solid rgba(112, 54, 182, 0.1);
          cursor: pointer;
          i {
            top: 50%;
            left: 50%;
            font-size: 20px;
            color: #ffffff;
            position: absolute;
            transform: translate(-50%, -50%);
          }
        }
      }
      nav {
        ul {
          a {
            display: list-item;
            position: relative;
            padding: 25px 10px 25px 78px;
            &:nth-child(5) {
              i {
                font-size: 28px;
              }
            }
            &:nth-child(6) {
              i {
                font-size: 20px;
              }
            }
            &::after {
              content: "";
              top: 50%;
              left: -1px;
              width: 4px;
              height: 0;
              border-radius: 5px;
              position: absolute;
              background: #7036b6;
              transform: translate(0, -50%);
              transition: all 0.4s;
            }
            font-size: 20px;
            color: #2b1a4f;
            font-weight: 600;
            line-height: 24px;
            transition: all 0.4s;
            i {
              left: 40px;
              top: 50%;
              font-size: 25px;
              color: #2b1a4f;
              position: absolute;
              transform: translate(0, -50%);
              transition: all 0.4s;
            }
            span {
              left: 80px;
              bottom: 12px;
              display: block;
              font-size: 12px;
              color: #9999a5;
              line-height: 10px;
              position: absolute;
              font-weight: normal;
            }
          }
          a.active {
            &::after {
              height: 54px;
            }
            color: #7036b6;
            i {
              color: #7036b6;
            }
          }
          a.on_active {
            color: #9999a5;
            i {
              color: #9999a5;
            }
          }
        }
      }
      .help {
        z-index: 2;
        //left: 90px;
        //bottom: 34px;
        font-size: 18px;
        font-weight: 500;
        color: #cd2576;
        line-height: 22px;
        padding-left: 38px;
        position: relative;
        margin-top: 27px;
        margin-left: 40px;
        display: block;
        &:hover {
          i {
            transform: translate(0, -50%) rotate(360deg);
          }
        }
        i {
          left: 0;
          top: 45%;
          font-size: 26px;
          color: #cd2576;
          position: absolute;
          transform: translate(0, -50%);
          transition: all 0.6s;
        }
      }
      .languages {
        z-index: 2;
        left: 38px;
        bottom: 26px;
        font-size: 18px;
        color: #2b1a4f;
        line-height: 22px;
        //padding-left: 38px;
        position: absolute;
        font-weight: normal;
        transition: all 0.3s;
        &:hover {
          color: #cd2576;
        }
        img {
          left: 0;
          top: 45%;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          position: absolute;
          transform: translate(0, -50%);
        }
      }
    }
  }
  .platforma-nav-mobile.active {
    transform: translate(0, 0);
  }
  .header {
    display: flex;
    position: relative;
    border-radius: 10px;
    margin-bottom: 87px;
    padding: 8px 14px 9px;
    background: #ffffff;
    border: 1px solid rgba(112, 54, 182, 0.1);
    .logo {
      width: 98px;
      img {
        width: 100%;
        display: flex;
      }
    }
    .referral-link {
      left: 0;
      z-index: 3;
      width: 100%;
      bottom: -73px;
      position: absolute;
      border-radius: 15px;
      background: #f1ebf8;
      padding: 9px 12px 9px 13px;
      border: 1px solid rgba(112, 54, 182, 0.1);
      span {
        font-size: 12px;
        display: block;
        color: #9999a5;
        line-height: 15px;
        font-weight: normal;
        margin-bottom: -7px;
      }
      .link {
        display: flex;
        position: relative;
        align-items: flex-end;
        p {
          font-size: 14px;
          color: #2b1a4f;
          font-weight: 600;
          overflow: hidden;
          line-height: 17px;
          margin-right: 15px;
          white-space: nowrap;
          position: relative;
          &::after {
            content: "";
            top: 0;
            right: 0;
            width: 45px;
            height: 100%;
            position: absolute;
            background: linear-gradient(
              270deg,
              #f1ebf8 0%,
              rgba(241, 235, 248, 0) 100%
            );
          }
        }
        .share {
          display: none;
        }
        .copy {
          transform: translate(0, -4px);
          i {
            font-size: 25px;
            color: #7036b6;
          }
        }
      }
    }
    .wallet {
      display: none;
    }
    .open-nav {
      top: 0;
      right: 0;
      width: 44px;
      height: 100%;
      position: absolute;
      border-radius: 10px;
      background: #cd2576;
      border: 1px solid rgba(112, 54, 182, 0.1);
      cursor: pointer;
      i {
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 18px;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }
  }
  .platforma-content {
    flex-grow: 1;
    min-height: 0;
    .platforma-nav {
      display: none;
    }
    .content-tabs {
      width: 100%;
      //flex: 1;
      //display: flex;
      overflow-y: scroll;
      margin-right: -30px;
      padding-right: 10px;
      min-height: 0;
      flex-grow: 1;
      height: 100%;
      .dashboard {
        padding: 0 5px;
        .wallets {
          margin-bottom: 14px;
          .coin {
            margin-bottom: 14px;
            border-radius: 25px;
            background: #ffffff;
            border: 1px solid rgba(112, 54, 182, 0.1);
            .wallet-title {
              display: flex;
              padding: 15px 10px;
              align-items: center;
              justify-content: center;
              img {
                width: 24px;
                display: inline-block;
              }
              h3 {
                font-size: 14px;
                color: #2b1a4f;
                font-weight: 600;
                margin-left: 6px;
                line-height: 17px;
              }
            }
            .balance {
              margin: 0 -6px;
              display: flex;
              padding: 15px 13px;
              position: relative;
              border-radius: 15px;
              align-items: center;
              background: #7036b6;
              justify-content: center;
              span {
                top: 50%;
                left: 14px;
                font-size: 12px;
                color: #ffffff;
                line-height: 15px;
                position: absolute;
                font-weight: normal;
                transform: translate(0, -50%);
              }
              h2 {
                font-size: 24px;
                color: #ffffff;
                line-height: 29px;
                font-weight: bold;
                text-align: center;
                position: relative;
                transform: translate(0, -6px);
                flex-grow: 1;
                i {
                  left: 0;
                  width: 100%;
                  bottom: -12px;
                  display: block;
                  font-size: 10px;
                  line-height: 16px;
                  text-align: center;
                  position: absolute;
                  font-weight: normal;
                }
              }
              strong {
                top: 50%;
                right: 14px;
                font-size: 12px;
                color: #ffffff;
                font-weight: 600;
                line-height: 15px;
                position: absolute;
                transform: translate(0, -50%);
              }
            }
            ul {
              padding: 15px 10px 14px;
              li {
                display: flex;
                align-items: center;
                justify-content: center;
                a {
                  font-size: 14px;
                  font-weight: 500;
                  color: #7036b6;
                  line-height: 17px;
                  padding-left: 8px;
                  position: relative;
                  display: inline-block;
                  i {
                    top: 50%;
                    left: -13px;
                    font-size: 14px;
                    color: #7036b6;
                    position: absolute;
                    transform: translate(0, -50%);
                  }
                }
              }
            }
          }
          .token {
            border-radius: 25px;
            margin-bottom: 14px;
            background: #ffffff;
            border: 1px solid rgba(112, 54, 182, 0.1);
            .wallet-title {
              display: flex;
              padding: 15px 10px;
              align-items: center;
              justify-content: center;
              img {
                width: 24px;
                display: inline-block;
              }
              h3 {
                font-size: 14px;
                color: #cd2576;
                font-weight: 600;
                margin-left: 6px;
                line-height: 17px;
              }
            }
            .balance {
              margin: 0 -6px;
              display: flex;
              padding: 15px 13px;
              position: relative;
              border-radius: 15px;
              align-items: center;
              background: #7036b6;
              justify-content: center;
              span {
                top: 50%;
                left: 14px;
                font-size: 12px;
                color: #ffffff;
                line-height: 15px;
                font-weight: normal;
                position: absolute;
                transform: translate(0, -50%);
              }
              h2 {
                font-size: 24px;
                color: #ffffff;
                line-height: 29px;
                font-weight: bold;
                text-align: center;
                flex-grow: 1;
              }
              strong {
                top: 50%;
                right: 14px;
                font-size: 12px;
                color: #ffffff;
                font-weight: 600;
                line-height: 15px;
                position: absolute;
                transform: translate(0, -50%);
              }
            }
            ul {
              padding: 15px 10px 14px;
              li {
                display: flex;
                font-size: 14px;
                color: #7036b6;
                font-weight: 500;
                line-height: 17px;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .usdt {
            border-radius: 25px;
            background: #ffffff;
            border: 1px solid rgba(112, 54, 182, 0.1);
            .wallet-title {
              display: flex;
              padding: 15px 10px;
              align-items: center;
              justify-content: center;
              img {
                width: 24px;
                display: inline-block;
              }
              h3 {
                font-size: 14px;
                color: #2b1a4f;
                font-weight: 600;
                margin-left: 6px;
                line-height: 17px;
              }
            }
            .balance {
              margin: 0 -6px;
              display: flex;
              padding: 15px 13px;
              position: relative;
              border-radius: 15px;
              align-items: center;
              background: #fae9f1;
              justify-content: center;
              span {
                top: 50%;
                left: 14px;
                font-size: 12px;
                color: #9999a5;
                line-height: 15px;
                position: absolute;
                font-weight: normal;
                transform: translate(0, -50%);
              }
              h2 {
                font-size: 24px;
                color: #2b1a4f;
                line-height: 29px;
                font-weight: bold;
                text-align: center;
                flex-grow: 1;
              }
              strong {
                top: 50%;
                right: 14px;
                font-size: 12px;
                color: #9999a5;
                font-weight: 600;
                line-height: 15px;
                position: absolute;
                transform: translate(0, -50%);
              }
            }
            ul {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 15px 10px 14px;
              li {
                margin: 0 20px;
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                &::after {
                  content: "";
                  top: 50%;
                  width: 1px;
                  right: -13px;
                  height: 22px;
                  position: absolute;
                  background: rgba(112, 54, 182, 0.3);
                  transform: translate(0, -50%);
                }
                &:first-child {
                  margin-left: 0;
                }
                &:last-child {
                  margin-right: 0;
                  &::after {
                    display: none;
                  }
                }
                a {
                  font-size: 14px;
                  font-weight: 500;
                  color: #7036b6;
                  line-height: 17px;
                  padding-left: 8px;
                  position: relative;
                  display: inline-block;
                  i {
                    top: 50%;
                    left: -13px;
                    font-size: 14px;
                    color: #7036b6;
                    position: absolute;
                    transform: translate(0, -50%);
                  }
                }
              }
            }
          }
        }
        .referral-exchange {
          .exchange {
            margin-bottom: 20px;
            border-radius: 25px;
            background: #ffffff;
            border-top: none;
            border: 1px solid rgba(112, 54, 182, 0.1);
            .title-exchange {
              margin: 0 -6px;
              display: flex;
              padding: 15px 10px;
              align-items: center;
              border-radius: 15px;
              background: #cd2576;
              justify-content: center;
              i {
                font-size: 16px;
                color: #ffffff;
              }
              h4 {
                font-size: 16px;
                margin-left: 5px;
                font-weight: 600;
                color: #ffffff;
                line-height: 20px;
              }
            }
            p {
              font-size: 12px;
              color: #9999a5;
              line-height: 18px;
              text-align: center;
              font-weight: normal;
              padding: 9px 25px 7px;
            }
            .course {
              display: block;
              font-size: 14px;
              color: #7036b6;
              font-weight: 500;
              line-height: 17px;
              text-align: center;
              margin-bottom: 15px;
            }
            form {
              padding: 0 10px;
              .inputs-content {
                position: relative;
                margin-bottom: 2px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(112, 54, 182, 0.1);
              }
              .inputs {
                .input {
                  display: flex;
                  margin-bottom: 18px;
                  border-radius: 15px;
                  align-items: center;
                  background: #f1ebf8;
                  padding: 7px 6px 10px 14px;
                  justify-content: space-between;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  div {
                    width: 60%;
                    label {
                      display: block;
                      font-size: 12px;
                      color: #7036b6;
                      line-height: 15px;
                      margin-bottom: 5px;
                      font-weight: normal;
                    }
                    input {
                      width: 100%;
                      height: 20px;
                      border: none;
                      font-size: 14px;
                      color: #7036b6;
                      font-weight: 600;
                      line-height: 17px;
                      background: transparent;
                      font-family: "Montserrat", sans-serif;
                    }
                    input::placeholder {
                      color: #9999a5;
                    }
                  }
                  strong {
                    width: 60px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #7036b6;
                    line-height: 17px;
                  }
                }
              }
              .change {
                top: 46%;
                left: 50%;
                z-index: 2;
                width: 44px;
                height: 44px;
                cursor: pointer;
                border-radius: 50%;
                position: absolute;
                background: #7036b6;
                border: 2px solid #ffffff;
                transform: translate(-50%, -50%);
                transition: all 0.5s;
                &:hover {
                  transform: translate(-50%, -50%) rotate(360deg);
                }
                i {
                  top: 50%;
                  left: 50%;
                  color: #fff;
                  font-size: 20px;
                  position: absolute;
                  transform: translate(-50%, -50%);
                }
              }
              button {
                width: 100%;
                height: 44px;
                border: none;
                font-size: 14px;
                display: flex;
                color: #7036b6;
                font-weight: 500;
                line-height: 17px;
                align-items: center;
                justify-content: center;
                background: transparent;
                i {
                  display: block;
                  font-size: 16px;
                  color: #7036b6;
                  margin-right: 6px;
                }
              }
            }
          }
          .referral-program {
            border-radius: 25px;
            border-top: none;
            background: #ffffff;
            border: 1px solid rgba(112, 54, 182, 0.1);
            .referral-title {
              margin: 0 -6px;
              padding: 15px 10px;
              border-radius: 15px;
              background: #fae9f1;
              h4 {
                font-size: 16px;
                display: flex;
                color: #2b1a4f;
                font-weight: 600;
                line-height: 20px;
                align-items: center;
                justify-content: center;
                i {
                  font-size: 18px;
                  color: #7036b6;
                  margin-right: 5px;
                }
              }
              .name {
                display: none;
              }
            }
            .referral-content {
              padding: 12px 9px 6px;
              .program-title {
                margin-bottom: 10px;
                ul {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  li {
                    width: 30%;
                    font-size: 11px;
                    color: #9999a5;
                    line-height: 13px;
                    padding-right: 15px;
                    font-weight: normal;
                    &:nth-child(1) {
                      width: 27%;
                    }
                    &:nth-child(2) {
                      width: 32%;
                    }
                    &:nth-child(3) {
                      width: 41%;
                    }
                    &:nth-child(4) {
                      display: none;
                    }
                  }
                }
              }
              .program-content {
                .program-item {
                  .item {
                    cursor: pointer;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 12px 0;
                    position: relative;
                    align-items: center;
                    border-top: 1px solid rgba(136, 136, 136, 0.15);
                    .lvl {
                      width: 27%;
                      font-size: 16px;
                      color: #7036b6;
                      line-height: 20px;
                      padding-right: 15px;
                      font-weight: normal;
                    }
                    .participants {
                      width: 32%;
                      font-size: 16px;
                      color: #2b1a4f;
                      line-height: 20px;
                      padding-right: 15px;
                      font-weight: normal;
                    }
                    .profitability {
                      width: 41%;
                      font-size: 16px;
                      color: #2b1a4f;
                      font-weight: 600;
                      line-height: 20px;
                      padding-right: 25px;
                    }
                    .reward {
                      width: 100%;
                      margin-top: 9px;
                      font-size: 16px;
                      color: #9999a5;
                      padding: 8px 10px;
                      line-height: 20px;
                      text-align: center;
                      border-radius: 15px;
                      font-weight: normal;
                      background: #f1ebf8;
                      span {
                        display: block;
                        color: #9999a5;
                        font-size: 11px;
                        line-height: 13px;
                        margin-bottom: 6px;
                        font-weight: normal;
                      }
                      strong {
                        font-size: 16px;
                        color: #7036b6;
                        font-weight: 600;
                        line-height: 20px;

                        &:not(:first-of-type) {
                          margin-left: 20px;
                        }
                      }
                      i {
                        color: #9999a5;
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: normal;
                      }
                    }
                    .open-drop {
                      top: 11px;
                      right: 0;
                      width: 18px;
                      height: 18px;
                      cursor: pointer;
                      position: absolute;
                      &:hover {
                        &::before {
                          transform: translate(-50%, -50%) rotate(90deg);
                        }
                      }
                      &::after {
                        content: "";
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        transform: translate(-50%, -50%);
                        background: rgba(112, 54, 182, 0.4);
                        transition: all 0.5s;
                      }
                      &::before {
                        content: "";
                        top: 50%;
                        left: 50%;
                        width: 1px;
                        height: 100%;
                        position: absolute;
                        transform: translate(-50%, -50%);
                        background: rgba(112, 54, 182, 0.4);
                        transition: all 0.5s;
                      }
                    }
                  }
                  .drop {
                    display: none;
                    margin: 0 -9px;
                    padding: 10px 0;
                    background: #fcf4f8;
                    border-top: 1px solid rgba(112, 54, 182, 0.2);
                    border-bottom: 1px solid rgba(112, 54, 182, 0.2);
                    .drop-content {
                      max-height: 483px;
                      ul {
                        display: none;
                      }
                      .drop-items {
                        display: flex;
                        flex-wrap: wrap;
                        position: relative;
                        margin-bottom: 10px;
                        padding: 5px 8px 15px 19px;
                        &:last-child {
                          &::after {
                            display: none;
                          }
                        }
                        &::after {
                          content: "";
                          left: 0;
                          bottom: 0;
                          width: 100%;
                          height: 1px;
                          position: absolute;
                          background: rgba(112, 54, 182, 0.2);
                        }
                        .drop-item {
                          width: 55%;
                          font-size: 14px;
                          color: #000000;
                          line-height: 17px;
                          font-weight: normal;
                          margin-bottom: 10px;
                          padding-bottom: 9px;
                          border-bottom: 1px solid rgba(136, 136, 136, 0.05);
                          &:nth-child(even) {
                            width: 45%;
                          }
                          &:nth-child(3) {
                            margin-bottom: 0;
                            padding-bottom: 0;
                            border-bottom: none;
                          }
                          &:nth-child(4) {
                            margin-bottom: 0;
                            padding-bottom: 0;
                            border-bottom: none;
                          }
                          i {
                            display: block;
                            font-size: 12px;
                            color: #9999a5;
                            line-height: 15px;
                            margin-bottom: 6px;
                            font-weight: normal;
                          }
                          span {
                            font-size: 14px;
                            color: #7036b6;
                            line-height: 17px;
                            font-weight: normal;
                          }
                          strong {
                            font-size: 14px;
                            color: #000000;
                            font-weight: 600;
                            line-height: 17px;
                          }
                        }
                      }
                    }
                    .mCustomScrollBox {
                      margin: 0;
                    }
                    .mCSB_inside > .mCSB_container {
                      margin-right: 12px;
                    }
                    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
                      opacity: 0.2;
                      border-radius: 5px;
                      background: #cd2576;
                    }
                    .mCSB_scrollTools .mCSB_draggerRail {
                      background: transparent;
                    }
                    .mCSB_scrollTools .mCSB_draggerContainer {
                      right: -4px;
                    }
                  }
                }
                .program-item.active {
                  .item {
                    .open-drop {
                      &::before {
                        transform: translate(-50%, -50%) rotate(90deg);
                      }
                    }
                  }
                  .drop {
                    display: block;
                    overflow: auto;
                  }
                }
              }
            }
          }
        }
      }
      .investment {
        padding: 0 5px;
        .finance {
          margin-bottom: 15px;
        }
        .staking {
          z-index: 2;
          border-top: none;
          position: relative;
          margin-bottom: 15px;
          border-radius: 25px;
          background: #ffffff;
          border: 1px solid rgba(112, 54, 182, 0.1);
          .staking-title {
            margin: 0 -6px;
            display: flex;
            padding: 15px 10px;
            position: relative;
            align-items: center;
            border-radius: 15px;
            background: #cd2576;
            justify-content: center;
            i {
              font-size: 16px;
              color: #ffffff;
            }
            h4 {
              font-size: 16px;
              margin-left: 5px;
              font-weight: 600;
              color: #ffffff;
              line-height: 20px;
            }
            span {
              top: 50%;
              z-index: 2;
              right: 14px;
              position: absolute;
              transform: translate(0, -50%);
              &:hover {
                i {
                  opacity: 1;
                }
                .info-window {
                  display: block;
                }
              }
              i {
                opacity: 0.6;
                font-size: 21px;
                color: #ffffff;
                display: inline-block;
                transition: all 0.5s;
              }
              .info-window {
                top: 40px;
                right: -14px;
                width: 220px;
                display: none;
                padding: 12px;
                font-size: 13px;
                color: #7036b6;
                line-height: 19px;
                font-weight: normal;
                position: absolute;
                border-radius: 10px;
                background: #ffffff;
                border: 1px solid rgba(112, 54, 182, 0.5);
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
                &:after {
                  content: "";
                  position: absolute;
                  top: -16px;
                  right: 17px;
                  width: 13px;
                  height: 21px;
                  background: url("../images/info-window.png") no-repeat 50% 50%;
                  background-size: cover;
                  transform: rotate(90deg);
                }
                strong {
                  display: block;
                  font-size: 14px;
                  color: #7036b6;
                  font-weight: 600;
                  line-height: 17px;
                  margin-bottom: 3px;
                }
              }
            }
          }
          .staking-content {
            padding: 15px 14px 9px;
            .balance {
              display: flex;
              margin-bottom: 15px;
              padding-bottom: 14px;
              align-items: flex-start;
              justify-content: space-between;
              border-bottom: 1px solid rgba(112, 54, 182, 0.1);
              .balance-item {
                width: 50%;
                &:first-child {
                  padding-right: 5px;
                }
                &:last-child {
                  text-align: right;
                  padding-left: 5px;
                }
                span {
                  display: block;
                  font-size: 12px;
                  color: #9999a5;
                  line-height: 15px;
                  margin-bottom: 6px;
                  font-weight: normal;
                }
                strong {
                  display: block;
                  font-size: 16px;
                  color: #2b1a4f;
                  font-weight: 600;
                  line-height: 20px;
                }
                a {
                  font-size: 14px;
                  font-weight: 500;
                  color: #7036b6;
                  line-height: 17px;
                  position: relative;
                  padding-left: 15px;
                  display: inline-block;
                  i {
                    left: 0;
                    top: 50%;
                    font-size: 12px;
                    color: #7036b6;
                    position: absolute;
                    transform: translate(0, -50%) rotate(180deg);
                  }
                }
              }
            }
            form {
              margin: 0 -5px;
              .items {
                margin-bottom: 8px;
                .item {
                  margin-bottom: 20px;
                  &:last-child {
                    margin-bottom: 0;
                    .input {
                      border-radius: 15px 15px 0 0;
                    }
                  }
                  label {
                    display: block;
                    font-size: 12px;
                    color: #7036b6;
                    line-height: 15px;
                    text-align: center;
                    margin-bottom: 8px;
                    font-weight: normal;
                  }
                  .input {
                    padding: 0 80px;
                    position: relative;
                    border-radius: 15px;
                    background: #f1ebf8;
                    strong {
                      top: 50%;
                      left: 10px;
                      font-size: 12px;
                      color: #7036b6;
                      font-weight: 500;
                      line-height: 15px;
                      position: absolute;
                      transform: translate(0, -50%);
                    }
                    span {
                      top: 50%;
                      right: 10px;
                      opacity: 0.6;
                      font-size: 12px;
                      color: #7036b6;
                      font-weight: 500;
                      line-height: 15px;
                      position: absolute;
                      transform: translate(0, -50%);
                    }
                    input {
                      width: 100%;
                      height: 47px;
                      border: none;
                      font-size: 14px;
                      color: #7036b6;
                      font-weight: 600;
                      line-height: 17px;
                      text-align: center;
                      background: transparent;
                      font-family: "Montserrat", sans-serif;
                    }
                    input::placeholder {
                      color: #9999a5;
                    }
                  }
                  .days {
                    display: flex;
                    padding: 5px 10px;
                    align-items: center;
                    background: #e3d9f2;
                    border-radius: 0 0 15px 15px;
                    justify-content: space-between;
                    li {
                      opacity: 0.5;
                      cursor: pointer;
                      font-size: 12px;
                      color: #cd2576;
                      font-weight: 600;
                      line-height: 15px;
                      transition: all 0.3s;
                      &:hover {
                        opacity: 1;
                      }
                    }
                    li.active {
                      opacity: 1;
                    }
                  }
                }
              }
              .profitability {
                display: flex;
                font-size: 12px;
                margin-bottom: 10px;
                color: #7036b6;
                line-height: 15px;
                text-align: center;
                padding: 13px 10px;
                font-weight: normal;
                align-items: center;
                justify-content: center;
                border-top: 1px solid rgba(112, 54, 182, 0.1);
                border-bottom: 1px solid rgba(112, 54, 182, 0.1);
                .info {
                  position: relative;
                  &:hover {
                    .info-window {
                      display: block;
                    }
                  }
                  i {
                    font-size: 12px;
                    color: #7036b6;
                    margin-left: 6px;
                    display: inline-block;
                  }
                  .info-window {
                    top: 30px;
                    right: -18px;
                    width: 220px;
                    display: none;
                    padding: 12px;
                    font-size: 13px;
                    text-align: left;
                    color: #7036b6;
                    line-height: 19px;
                    font-weight: normal;
                    position: absolute;
                    border-radius: 10px;
                    background: #ffffff;
                    border: 1px solid rgba(112, 54, 182, 0.5);
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
                    &:after {
                      content: "";
                      position: absolute;
                      top: -16px;
                      right: 17px;
                      width: 13px;
                      height: 21px;
                      background: url("../images/info-window.png") no-repeat 50%
                        50%;
                      background-size: cover;
                      transform: rotate(90deg);
                    }
                    strong {
                      display: block;
                      font-size: 14px;
                      color: #7036b6;
                      font-weight: 600;
                      line-height: 17px;
                      margin-bottom: 3px;
                    }
                  }
                }
              }
              button {
                width: 100%;
                border: none;
                height: 33px;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                color: #7036b6;
                line-height: 17px;
                align-items: center;
                justify-content: center;
                background: transparent;
                i {
                  font-size: 14px;
                  color: #7036b6;
                  margin-right: 5px;
                  display: inline-block;
                }
              }
            }
          }
        }
        .my-balance {
          height: 392px;
          display: flex;
          overflow: hidden;
          margin-bottom: 15px;
          position: relative;
          padding: 10px 15px;
          border-radius: 25px;
          align-items: flex-end;
          background: linear-gradient(
            0.9deg,
            #361075 -2.65%,
            #552498 37.67%,
            #7036b6 80.56%,
            #7a3dc2 104.61%
          );
          svg {
            top: 20px;
            left: 50%;
            z-index: 0;
            width: 105%;
            position: absolute;
            transform: translate(-50%, 0);
          }
          .today {
            top: 20px;
            left: 20px;
            z-index: 1;
            position: absolute;
            p {
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 5px;
              font-weight: normal;
              color: rgba(255, 255, 255, 0.6);
            }
            h5 {
              font-size: 18px;
              color: #ffffff;
              font-weight: 600;
              line-height: 22px;
              font-style: normal;
              margin-bottom: 5px;
              span {
                font-size: 12px;
                color: #ffffff;
                line-height: 15px;
                margin-left: 2px;
                font-weight: normal;
                display: inline-block;
              }
            }
            i {
              font-size: 12px;
              font-weight: 600;
              line-height: 130%;
              font-style: normal;
              text-shadow: 0px 0px 11px #bd32ff;
              background: -webkit-linear-gradient(#b867ff, #fe238c);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .overall {
            z-index: 1;
            width: 100%;
            position: relative;
            text-align: center;
            p {
              opacity: 0.6;
              font-size: 14px;
              color: #ffffff;
              font-weight: 500;
              line-height: 17px;
              margin-bottom: 7px;
            }
            h3 {
              font-size: 28px;
              color: #ffffff;
              font-weight: bold;
              line-height: 34px;
              margin-bottom: 3px;
            }
            span {
              font-size: 12px;
              color: #ffffff;
              line-height: 15px;
              font-weight: normal;
              margin-bottom: 10px;
              display: inline-block;
            }
            .usd {
              color: lightgreen;
              margin-bottom: 20px;
            }
            a {
              width: 100%;
              display: flex;
              font-size: 14px;
              color: #ffffff;
              font-weight: 500;
              line-height: 17px;
              align-items: center;
              padding: 15px 4px 4px;
              justify-content: center;
              border-top: 1px solid rgba(255, 255, 255, 0.2);
              i {
                font-size: 12px;
                color: #ffffff;
                margin-right: 5px;
                display: inline-block;
              }
            }
          }
        }
        .history-investing {
          border-top: none;
          border-radius: 25px;
          background: #ffffff;
          border: 1px solid rgba(112, 54, 182, 0.1);
          .investing-title {
            margin: 0 -6px;
            display: flex;
            padding: 15px 10px;
            position: relative;
            align-items: center;
            border-radius: 15px;
            background: #fae9f1;
            justify-content: center;
            i {
              font-size: 16px;
              color: #7036b6;
            }
            h4 {
              font-size: 16px;
              margin-left: 5px;
              font-weight: 600;
              color: #2b1a4f;
              line-height: 20px;
            }
          }
          .history-content {
            padding: 15px 8px 4px;
            .history-title {
              display: none;
            }
            .history-items {
              .history-item {
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                padding: 12px 0 15px;
                border-top: 1px solid rgba(136, 136, 136, 0.15);
                .data {
                  width: 45%;
                  font-size: 16px;
                  color: #7036b6;
                  line-height: 20px;
                  padding-right: 15px;
                  font-weight: normal;
                  span {
                    display: block;
                    font-size: 12px;
                    color: #9999a5;
                    line-height: 15px;
                    font-weight: normal;
                    margin-bottom: 6px;
                  }
                }
                .number {
                  display: none;
                }
                .accruals {
                  width: 40%;
                  font-size: 16px;
                  color: #2b1a4f;
                  font-weight: 600;
                  line-height: 20px;
                  padding-right: 15px;
                  span {
                    display: block;
                    font-size: 12px;
                    color: #9999a5;
                    line-height: 15px;
                    font-weight: normal;
                    margin-bottom: 6px;
                  }
                }
                .common-accruals {
                  width: 15%;
                  font-size: 16px;
                  color: #7036b6;
                  font-weight: 600;
                  line-height: 20px;
                  span {
                    display: block;
                    font-size: 12px;
                    color: #9999a5;
                    line-height: 15px;
                    font-weight: normal;
                    margin-bottom: 6px;
                  }
                }
                .number-mob {
                  width: 100%;
                  margin-top: 9px;
                  font-size: 16px;
                  color: #9999a5;
                  padding: 8px 10px;
                  line-height: 20px;
                  text-align: center;
                  border-radius: 15px;
                  font-weight: normal;
                  background: #f1ebf8;
                  span {
                    display: block;
                    color: #9999a5;
                    font-size: 11px;
                    line-height: 13px;
                    margin-bottom: 6px;
                    font-weight: normal;
                  }
                  strong {
                    font-size: 16px;
                    color: #7036b6;
                    font-weight: 600;
                    line-height: 20px;
                  }
                  i {
                    color: #9999a5;
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: normal;
                  }
                }
              }
            }
          }
        }
        .deposits {
          border-top: none;
          background: #fff;
          border-radius: 25px;
          border: 1px solid rgba(112, 54, 182, 0.1);
          .deposits-title {
            margin: 0 -6px;
            display: flex;
            padding: 15px 10px;
            position: relative;
            align-items: center;
            border-radius: 15px;
            background: #cd2576;
            justify-content: center;
            i {
              font-size: 18px;
              color: #ffffff;
            }
            h4 {
              font-size: 16px;
              margin-left: 5px;
              font-weight: 600;
              color: #ffffff;
              line-height: 20px;
            }
          }
          .deposits-content {
            padding: 7px 0 18px;
            .deposits-cap {
              padding: 5px;
              ul {
                display: flex;
              }
              li {
                &:nth-child(1) {
                  width: 26%;
                  padding-right: 20px;
                }
                &:nth-child(2) {
                  width: 26%;
                  padding-right: 20px;
                }
                &:nth-child(3) {
                  width: 26%;
                  padding-right: 20px;
                }
                &:nth-child(4) {
                  width: 22%;
                }
                font-size: 12px;
                color: #9999a5;
                line-height: 15px;
                font-weight: normal;
              }
            }
            .deposits-items {
              .deposits-item {
                display: flex;
                margin: 0 -6px 6px;
                border-radius: 18px;
                position: relative;
                background: #fcf4f8;
                padding: 15px 15px 17px 10px;
                &:last-child {
                  margin-bottom: 0;
                }
                .rate {
                  width: 26%;
                  font-size: 12px;
                  color: #7036b6;
                  font-weight: 600;
                  line-height: 15px;
                  padding-right: 10px;
                }
                .period {
                  width: 27%;
                  font-size: 12px;
                  color: #2b1a4f;
                  line-height: 15px;
                  font-weight: normal;
                  padding-right: 10px;
                }
                .left {
                  width: 26%;
                  font-size: 12px;
                  color: #2b1a4f;
                  line-height: 15px;
                  font-weight: normal;
                  padding-right: 10px;
                }
                .data {
                  width: 21%;
                  font-size: 12px;
                  color: #7036b6;
                  line-height: 15px;
                  font-weight: normal;
                }
                .open {
                  top: 50%;
                  z-index: 2;
                  right: 10px;
                  cursor: pointer;
                  position: absolute;
                  transform: translate(0, -50%);
                  i {
                    font-size: 18px;
                    color: #cd2576;
                  }
                }
              }
            }
          }
        }
      }
      .farming {
        padding: 0 5px;
        .farming-title {
          margin: 0 -6px 20px;
          border-radius: 15px;
          background: #fae9f1;
          h4 {
            font-size: 16px;
            display: flex;
            color: #2b1a4f;
            font-weight: 500;
            line-height: 20px;
            align-items: center;
            i {
              font-size: 20px;
              color: #7036b6;
              margin-right: 5px;
            }
          }
          ul {
            display: flex;
            li {
              width: 50%;
              border-radius: 15px;
              transition: all 0.3s;
              a {
                width: 100%;
                font-size: 14px;
                display: flex;
                color: #2b1a4f;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
                padding: 13px 10px;
                justify-content: center;
                transition: all 0.3s;
                i {
                  font-size: 14px;
                  margin-right: 5px;
                  display: inline-block;
                  transition: all 0.3s;
                }
              }
            }
            li.active {
              background: linear-gradient(180deg, #cd2576 0%, #8a164e 100%);
              a {
                color: #ffffff;
                i {
                  color: #ffffff;
                }
              }
            }
          }
        }
        .staking {
          //z-index: 2;
          border-top: none;
          position: relative;
          margin-bottom: 15px;
          border-radius: 25px;
          background: #ffffff;
          border: 1px solid rgba(112, 54, 182, 0.1);
          .staking-title {
            margin: 0 -6px;
            display: flex;
            padding: 15px 10px;
            position: relative;
            align-items: center;
            border-radius: 15px;
            background: #cd2576;
            justify-content: center;
            img {
              width: 26px;
              display: block;
              margin-right: 4px;
            }
            i {
              font-size: 16px;
              color: #ffffff;
            }
            h4 {
              font-size: 20px;
              margin-left: 5px;
              font-weight: 600;
              color: #ffffff;
              line-height: 24px;
            }
            .info {
              top: 50%;
              z-index: 2;
              right: 14px;
              position: absolute;
              transform: translate(0, -50%);
              &:hover {
                i {
                  opacity: 1;
                }
                .info-window {
                  display: block;
                }
              }
              i {
                opacity: 0.6;
                font-size: 21px;
                color: #ffffff;
                display: inline-block;
                transition: all 0.5s;
              }
              .info-window {
                top: 40px;
                right: -14px;
                width: 220px;
                display: none;
                padding: 12px;
                font-size: 13px;
                color: #7036b6;
                line-height: 19px;
                font-weight: normal;
                position: absolute;
                border-radius: 10px;
                background: #ffffff;
                border: 1px solid rgba(112, 54, 182, 0.5);
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
                &:after {
                  content: "";
                  position: absolute;
                  top: -16px;
                  right: 17px;
                  width: 13px;
                  height: 21px;
                  background: url("../images/info-window.png") no-repeat 50% 50%;
                  background-size: cover;
                  transform: rotate(90deg);
                }
                strong {
                  display: block;
                  font-size: 14px;
                  color: #7036b6;
                  font-weight: 600;
                  line-height: 17px;
                  margin-bottom: 3px;
                }
              }
            }
          }
          .staking-content {
            padding: 15px 10px 9px;
            .balance {
              display: flex;
              margin-bottom: 15px;
              padding-bottom: 14px;
              align-items: flex-start;
              justify-content: space-between;
              border-bottom: 1px solid rgba(112, 54, 182, 0.1);
              .balance-item {
                width: 50%;
                &:first-child {
                  padding-right: 5px;
                }
                &:last-child {
                  text-align: right;
                  padding-left: 5px;
                }
                span {
                  display: block;
                  font-size: 12px;
                  color: #9999a5;
                  line-height: 15px;
                  margin-bottom: 6px;
                  font-weight: normal;
                }
                strong {
                  display: block;
                  font-size: 16px;
                  color: #2b1a4f;
                  font-weight: 600;
                  line-height: 20px;
                }
                a {
                  font-size: 14px;
                  font-weight: 500;
                  color: #7036b6;
                  line-height: 17px;
                  position: relative;
                  padding-left: 15px;
                  display: inline-block;
                  i {
                    left: 0;
                    top: 50%;
                    font-size: 12px;
                    color: #7036b6;
                    position: absolute;
                    transform: translate(0, -50%) rotate(180deg);
                  }
                }
              }
            }
            form {
              margin: 0 -5px;
              .items {
                margin-bottom: 8px;
                .item {
                  margin-bottom: 20px;
                  &:last-child {
                    margin-bottom: 0;
                    .input {
                      border-radius: 15px 15px 0 0;
                    }
                  }
                  label {
                    display: block;
                    font-size: 12px;
                    color: #7036b6;
                    line-height: 15px;
                    text-align: center;
                    margin-bottom: 8px;
                    font-weight: normal;
                  }
                  .input {
                    padding: 0 80px;
                    position: relative;
                    border-radius: 15px;
                    background: #f1ebf8;
                    strong {
                      top: 50%;
                      left: 10px;
                      font-size: 12px;
                      color: #7036b6;
                      font-weight: 500;
                      line-height: 15px;
                      position: absolute;
                      transform: translate(0, -50%);
                    }
                    span {
                      top: 50%;
                      right: 10px;
                      opacity: 0.6;
                      font-size: 12px;
                      color: #7036b6;
                      font-weight: 500;
                      line-height: 15px;
                      position: absolute;
                      transform: translate(0, -50%);
                    }
                    input {
                      width: 100%;
                      height: 47px;
                      border: none;
                      font-size: 14px;
                      color: #7036b6;
                      font-weight: 600;
                      line-height: 17px;
                      text-align: center;
                      background: transparent;
                      font-family: "Montserrat", sans-serif;
                    }
                    input::placeholder {
                      color: #9999a5;
                    }
                  }
                  .days {
                    display: flex;
                    padding: 5px 10px;
                    align-items: center;
                    background: #e3d9f2;
                    border-radius: 0 0 15px 15px;
                    justify-content: space-between;
                    li {
                      opacity: 0.5;
                      cursor: pointer;
                      font-size: 12px;
                      color: #cd2576;
                      font-weight: 600;
                      line-height: 15px;
                      position: relative;
                      transition: all 0.3s;
                      &:hover {
                        opacity: 1;
                        .present-info {
                          display: block;
                        }
                      }
                      i {
                        font-size: 11px;
                        text-shadow: (0px 0px 10px rgba(205, 37, 118, 0.6));
                      }
                      .present-info {
                        top: 27px;
                        left: 50%;
                        z-index: 2;
                        width: 200px;
                        padding: 15px;
                        display: none;
                        text-align: center;
                        position: absolute;
                        border-radius: 10px;
                        background: #cd2576;
                        box-shadow: 0px 12px 20px rgba(205, 37, 118, 0.4);
                        transform: translate(-50%, 0);
                        &::after {
                          content: "";
                          top: -7px;
                          left: 50%;
                          z-index: -1;
                          width: 14px;
                          height: 14px;
                          position: absolute;
                          background: #cd2576;
                          transform: translate(-50%, 0) rotate(45deg);
                        }
                        strong {
                          font-size: 12px;
                          color: #f8f5fb;
                          font-weight: 600;
                          line-height: 15px;
                        }
                        p {
                          font-size: 11px;
                          color: #ffffff;
                          line-height: 16px;
                          font-weight: normal;
                        }
                      }
                    }
                    li.active {
                      opacity: 1;
                    }
                    li.present {
                      opacity: 1;
                    }
                  }
                }
              }
              .profitability {
                display: flex;
                font-size: 12px;
                margin-bottom: 10px;
                color: #7036b6;
                line-height: 15px;
                text-align: center;
                padding: 13px 10px;
                font-weight: normal;
                align-items: center;
                justify-content: center;
                border-top: 1px solid rgba(112, 54, 182, 0.1);
                border-bottom: 1px solid rgba(112, 54, 182, 0.1);
                .info {
                  position: relative;
                  &:hover {
                    .info-window {
                      display: block;
                    }
                  }
                  i {
                    font-size: 12px;
                    color: #7036b6;
                    margin-left: 6px;
                    display: inline-block;
                  }
                  .info-window {
                    top: 30px;
                    right: -18px;
                    width: 220px;
                    display: none;
                    padding: 12px;
                    font-size: 13px;
                    text-align: left;
                    color: #7036b6;
                    line-height: 19px;
                    font-weight: normal;
                    position: absolute;
                    border-radius: 10px;
                    background: #ffffff;
                    border: 1px solid rgba(112, 54, 182, 0.5);
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
                    &:after {
                      content: "";
                      position: absolute;
                      top: -16px;
                      right: 17px;
                      width: 13px;
                      height: 21px;
                      background: url("../images/info-window.png") no-repeat 50%
                        50%;
                      background-size: cover;
                      transform: rotate(90deg);
                    }
                    strong {
                      display: block;
                      font-size: 14px;
                      color: #7036b6;
                      font-weight: 600;
                      line-height: 17px;
                      margin-bottom: 3px;
                    }
                  }
                }
              }
              .farm {
                width: 100%;
                border: none;
                height: 33px;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                color: #7036b6;
                line-height: 17px;
                align-items: center;
                justify-content: center;
                background: transparent;
                i {
                  font-size: 14px;
                  color: #7036b6;
                  margin-right: 5px;
                  display: inline-block;
                }
              }
            }
          }
        }
        .history-investing {
          border-top: none;
          border-radius: 25px;
          background: #ffffff;
          border: 1px solid rgba(112, 54, 182, 0.1);
          .active-coin {
            width: 726px;
          }
          .investing-title {
            margin: 0 -6px;
            display: flex;
            padding: 15px 10px;
            position: relative;
            align-items: center;
            border-radius: 15px;
            background: #fae9f1;
            i {
              font-size: 16px;
              color: #7036b6;
            }
            h4 {
              font-size: 16px;
              margin-left: 5px;
              font-weight: 600;
              color: #2b1a4f;
              line-height: 20px;
            }
          }
          .history-content {
            overflow-x: scroll;
            padding: 15px 8px 4px;
            .history-title {
              width: 725px;
              display: block;
              margin-bottom: 10px;
              ul {
                display: flex;
                li {
                  font-size: 12px;
                  color: #9999a5;
                  line-height: 15px;
                  font-weight: normal;
                  &:nth-child(1) {
                    width: 5%;
                  }
                  &:nth-child(2) {
                    width: 12%;
                  }
                  &:nth-child(3) {
                    width: 15%;
                  }
                  &:nth-child(4) {
                    width: 20%;
                  }
                  &:nth-child(5) {
                    width: 15%;
                  }
                  &:nth-child(6) {
                    width: 15%;
                  }
                  &:nth-child(7) {
                    width: 18%;
                  }
                }
              }
            }
            .history-items {
              width: 725px;
              .history-item {
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                padding: 11px 0 9px;
                border-top: 1px solid rgba(136, 136, 136, 0.15);
                .data {
                  width: 5%;
                  font-size: 16px;
                  color: #7036b6;
                  line-height: 20px;
                  font-weight: normal;
                }
                .coins {
                  width: 12%;
                  display: flex;
                  align-items: center;
                  img {
                    width: 22px;
                    display: block;
                    margin-right: 10px;
                  }
                  i {
                    font-size: 16px;
                    color: #2b1a4f;
                    line-height: 20px;
                    font-weight: normal;
                    display: inline-block;
                  }
                }
                .balance {
                  width: 15%;
                }
                .period {
                  width: 20%;
                  strong {
                    font-size: 16px;
                    color: #7036b6;
                    line-height: 20px;
                    position: relative;
                    font-weight: normal;
                    display: inline-block;
                    span {
                      color: #9999a5;
                      font-size: 16px;
                      line-height: 20px;
                      font-weight: normal;
                      display: inline-block;
                      i {
                        right: 0;
                        top: -6px;
                        font-size: 10px;
                        color: #7036b6;
                        line-height: 12px;
                        position: absolute;
                        font-weight: normal;
                        transform: translate(110%, 0);
                      }
                    }
                  }
                }
                .per-day {
                  width: 15%;
                  font-size: 16px;
                  color: #2b1a4f;
                  font-weight: 600;
                  line-height: 20px;
                }
                .profit {
                  width: 15%;
                  font-size: 16px;
                  color: #7036b6;
                  font-weight: 600;
                  line-height: 20px;
                }
                .withdraw {
                  width: 18%;
                  a {
                    font-size: 16px;
                    color: #7036b6;
                    font-weight: 500;
                    line-height: 20px;
                    position: relative;
                    padding-left: 22px;
                    display: inline-block;
                    i {
                      top: 50%;
                      left: 2px;
                      font-size: 14px;
                      color: #7036b6;
                      position: absolute;
                      transform: translate(0, -50%);
                    }
                  }
                }
              }
            }
          }
        }
      }
			.rebalancing {
				padding: 0 5px;
				.rebalancing-content{
					.rebalancing_staking{
						padding: 6px;
						border-radius: 25px;
						margin: 0 -6px 20px;
						background: linear-gradient(313deg, #3E167D 0%, #985FDD 100%);
						.staking-title{
							display: flex;
							padding-top: 15px;
							align-items: center;
							padding-bottom: 26px;
							justify-content: center;
							img{
								width: 26px;
								display: block;
								margin-right: 13px;
							}
							h4{
								color: #FFF;
								font-size: 20px;
								font-weight: 600;
								line-height: 24px; 
							}
						}
						.staking-content{
							form{
								margin-bottom: 30px;
								label{
									color: #FFF;
									display: block;
									font-size: 12px;
									font-weight: 400;
									line-height: 15px;
									margin-bottom: 8px;
									text-align: center;
								}
								.input{
									display: flex;
									position: relative;
									padding: 16px 10px;
									border-radius: 15px;
									align-items: center;
									background: #F1EBF8;
									justify-content: space-between;
									strong{
										color: #7036B6;
										font-size: 12px;
										font-weight: 500;
										line-height: 15px;
									}
									input{
										top: 0;
										left: 0;
										z-index: 2;
										width: 100%;
										border: none;
										height: 100%;
										font-size: 14px;
										color: #2B1A4F;
										font-weight: 600;
										line-height: 18px;
										padding: 10px 80px;
										text-align: center;
										position: absolute;
										border-radius: 15px;
										background: transparent;
									}
									input::placeholder{
										color: #9999A5;
									}
									span{
										color: #7036B6;
										font-size: 12px;
										font-weight: 500;
										line-height: 15px; 
									}
								}
							}
							.balance{
								padding-bottom: 24px;
								p{
									color: #FFF;
									font-size: 14px;
									font-weight: 400;
									line-height: 18px;
									padding-bottom: 8px;
									&:first-child{
										padding-bottom: 23px;
									}
									&:last-child{
										padding-bottom: 0;
									}
								}
							}
							.farm{
								color: #FFF;
								padding: 20px;
								margin: 0 14px;
								display: flex;
								font-size: 17px;
								font-weight: 400;
								line-height: 20px;
								align-items: center;
								justify-content: center;
								border-top: 1px solid #fff;
								i{
									display: block;
									margin-right: 4px;
								}
							}
						}
					}
				}
								.rebalancing_history-investing {
					.active-coin {
						width: 726px;
					}
					.investing-title {
						margin: 0 -6px 20px;
						display: flex;
						padding: 15px 10px;
						position: relative;
						align-items: center;
						border-radius: 15px;
						background: #fae9f1;
						i {
							font-size: 16px;
							color: #7036b6;
						}
						h4 {
							font-size: 16px;
							margin-left: 5px;
							font-weight: 600;
							color: #2b1a4f;
							line-height: 20px;
						}
					}
					.history-content {
						overflow-x: scroll;
						padding: 15px 8px 4px;
						.history-title {
							width: 725px;
							display: block;
							margin-bottom: 10px;
							ul {
								display: flex;
								li {
									font-size: 12px;
									color: #9999a5;
									line-height: 15px;
									font-weight: normal;
									&:nth-child(1) {
										width: 5%;
									}
									&:nth-child(2) {
										width: 15%;
									}
									&:nth-child(3) {
										width: 16%;
									}
									&:nth-child(4) {
										width: 30%;
									}
									&:nth-child(5) {
										width: 10%;
									}
									&:nth-child(6) {
										width: 10%;
									}
									&:nth-child(7) {
										width: 12%;
									}
								}
							}
						}
						.history-items {
							width: 725px;
							.history-item {
								cursor: pointer;
								display: flex;
								flex-wrap: wrap;
								position: relative;
								padding: 11px 0 9px;
								border-top: 1px solid rgba(136, 136, 136, 0.15);
								.data {
									width: 5%;
									font-size: 16px;
									color: #7036b6;
									line-height: 20px;
									font-weight: normal;
								}
								.coins {
									width: 15%;
									display: flex;
									align-items: center;
									img {
										width: 22px;
										display: block;
										margin-right: 10px;
									}
									i {
										font-size: 16px;
										color: #2b1a4f;
										line-height: 20px;
										font-weight: normal;
										display: inline-block;
									}
								}
								.balance {
									width: 16%;
									font-size: 16px;
									color: #7036b6;
									font-weight: 600;
									line-height: 20px;
								}
								.period {
									width: 30%;
									strong {
										font-size: 16px;
										color: #7036b6;
										line-height: 20px;
										position: relative;
										font-weight: normal;
										display: inline-block;
										span {
											color: #9999a5;
											font-size: 16px;
											line-height: 20px;
											font-weight: normal;
											display: inline-block;
											i {
												right: 0;
												top: -6px;
												font-size: 10px;
												color: #7036b6;
												line-height: 12px;
												position: absolute;
												font-weight: normal;
												transform: translate(110%, 0);
											}
										}
									}
								}
								.per-day {
									width: 10%;
									font-size: 16px;
									color: #2b1a4f;
									font-weight: 600;
									line-height: 20px;
								}
								.profit {
									width: 10%;
									font-size: 16px;
									color: #7036b6;
									font-weight: 600;
									line-height: 20px;
								}
								.withdraw {
									width: 12%;
									a {
										font-size: 14px;
										color: #7036b6;
										font-weight: 500;
										line-height: 20px;
										position: relative;
										padding-left: 20px;
										display: inline-block;
										i {
											top: 50%;
											left: 2px;
											font-size: 14px;
											color: #7036b6;
											position: absolute;
											transform: translate(0, -50%);
										}
									}
								}
							}
						}
					}
					.rebalancing_investing{
						padding: 6px;
						border-radius: 25px;
						margin: 0 -6px 20px;
						background: linear-gradient(313deg, #3E167D 0%, #985FDD 100%);
						.title{
							display: flex;
							padding-top: 15px;
							align-items: center;
							padding-bottom: 26px;
							justify-content: center;
							img{
								width: 26px;
								display: block;
								margin-right: 13px;
							}
							h4{
								color: #FFF;
								font-size: 20px;
								font-weight: 600;
								line-height: 24px;
							}
						}
						form{
							margin-bottom: 30px;
							.item{
								margin-bottom: 25px;
								position: relative;
								&:nth-child(1){
									z-index: 3;
								}
								&:nth-child(2){
									z-index: 2;
								}
								&:nth-child(3){
									z-index: 1;
								}
								&:last-child{
									margin-bottom: 0;
								}
							}
							label{
								color: #FFF;
								display: block;
								font-size: 12px;
								font-weight: 400;
								line-height: 15px;
								margin-bottom: 8px;
								text-align: center;
							}
							.input{
								z-index: 0;
								display: flex;
								position: relative;
								padding: 16px 10px;
								border-radius: 15px;
								align-items: center;
								background: #F1EBF8;
								justify-content: space-between;

								strong{
									color: #7036B6;
									font-size: 12px;
									font-weight: 500;
									line-height: 15px;
								}
								input{
									top: 0;
									left: 0;
									z-index: 2;
									width: 100%;
									border: none;
									height: 100%;
									font-size: 14px;
									color: #2B1A4F;
									font-weight: 600;
									line-height: 18px;
									text-align: right;
									position: absolute;
									border-radius: 15px;
									background: transparent;
									padding: 10px 65px 10px 140px;
								}
								input::placeholder{
									color: #9999A5;
								}
								.info{
									z-index: 3;
									opacity: 0.6;
									cursor: pointer;
									font-size: 12px;
									font-weight: 500;
									color: #7036B6;
									line-height: 15px;
                                      margin-right: -3px;
									&:hover {
										opacity: 1;
										.info-window {
											display: block;
										}
									}
									i {
                                        margin-left: 2px;
                                        opacity: 1;
                                        font-size: 21px;
                                        color: #632ca4;
										font-size: 14px;
										transition: all 0.5s;
									}
									.info-window {
										top: 40px;
										right: -14px;
										width: 220px;
										display: none;
										padding: 12px;
										font-size: 13px;
										color: #7036b6;
										line-height: 19px;
										font-weight: normal;
										position: absolute;
										border-radius: 10px;
										background: #ffffff;
										border: 1px solid rgba(112, 54, 182, 0.5);
										box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
										&:after {
											content: '';
											position: absolute;
											top: -16px;
											right: 17px;
											width: 13px;
											height: 21px;
											background: url('../images/info-window.png') no-repeat 50% 50%;
											background-size: cover;
											transform: rotate(90deg);
										}
										strong {
											display: block;
											font-size: 14px;
											color: #7036b6;
											font-weight: 600;
											line-height: 17px;
											margin-bottom: 3px;
										}
									}
								}
							}
						}
						.balance{
							padding-bottom: 20px;
							span{
								color: #FFF;
								display: block;
								font-size: 14px;
								font-weight: 400;
								line-height: 18px;
								text-align: center;
								i{
									opacity: 0.6;
									font-weight: 500;
								}
							}
						}
						.farm{
							color: #FFF;
							padding: 20px;
							margin: 0 14px;
							display: flex;
							font-size: 17px;
							font-weight: 400;
							line-height: 20px;
							align-items: center;
							justify-content: center;
							border-top: 1px solid #fff;
							i{
								display: block;
								margin-right: 4px;
							}
						}
					}
				}
			}
    }
    .mCustomScrollBox {
      margin: 0 -5px;
    }
  }
  @media (min-width: 768px) {
    .platforma-nav-mobile {
      .nav-mobile-content {
        .wallet-mobile {
          padding: 19px 19px 40px 39px;
          .wallet {
            margin-right: 29px;
            .wallet-text {
              margin-left: 10px;
              padding-right: 47px;
              span {
                font-size: 14px;
                line-height: 18px;
              }
              strong {
                font-size: 16px;
                line-height: 22px;
              }
              a {
                top: 50%;
              }
            }
          }
          .close-nav {
            width: 64px;
            height: 64px;
            i {
              font-size: 25px;
            }
          }
        }
      }
    }
    .header {
      margin-bottom: 104px;
      border-radius: 25px;
      align-items: center;
      padding: 10px 19px 10px;
      justify-content: space-between;
      .logo {
        top: 14px;
        width: 133px;
      }
      .wallet {
        display: flex;
        padding-right: 0;
        margin-right: 72px;
        align-items: center;
        img {
          width: 42px;
        }
        .wallet-text {
          display: flex;
          margin-left: 10px;
          position: relative;
          padding-right: 47px;
          flex-direction: column;
          span {
            font-size: 14px;
            color: #7036b6;
            font-weight: 600;
            line-height: 17px;
            margin-bottom: 5px;
            display: inline-block;
          }
          strong {
            font-size: 16px;
            color: #2b1a4f;
            font-weight: 600;
            line-height: 20px;
            display: inline-block;
          }
          a {
            right: 0;
            top: 50%;
            font-size: 30px;
            color: #7036b6;
            position: absolute;
            transform: translate(0, -50%);
          }
        }
      }
      .referral-link {
        bottom: -85px;
        text-align: center;
        span {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 6px;
        }
        .link {
          justify-content: center;
          p {
            font-size: 16px;
            line-height: 20px;
            margin-right: 10px;
            &::after {
              display: none;
            }
          }
          .share {
            display: none;
            font-size: 18px;
            transform: translate(0, -2px);
          }
        }
      }
      .open-nav {
        width: 62px;
        i {
          font-size: 25px;
        }
      }
    }
    .platforma-content {
      .content-tabs {
        .dashboard {
          .wallets {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            align-items: flex-start;
            justify-content: space-between;
            .coin {
              width: 100%;
              margin-bottom: 20px;
              .wallet-title {
                padding: 19px 10px;
                img {
                  width: 28px;
                }
                h3 {
                  font-size: 18px;
                  margin-left: 7px;
                  line-height: 22px;
                }
              }
              .balance {
                padding: 19px 13px;
                h2 {
                  font-size: 28px;
                  line-height: 34px;
                  flex-grow: 1;
                }
              }
              ul {
                padding: 20px 10px 18px;
                li {
                  a {
                    font-size: 16px;
                    line-height: 20px;
                    padding-left: 20px;
                    i {
                      left: 0;
                      top: 50%;
                      font-size: 15px;
                    }
                  }
                }
              }
            }
            .token {
              width: 48.5%;
              margin-bottom: 10px;
              .wallet-title {
                padding: 19px 10px;
                img {
                  width: 28px;
                }
                h3 {
                  font-size: 18px;
                  margin-left: 7px;
                  line-height: 22px;
                }
              }
              .balance {
                padding: 19px 13px;
                h2 {
                  font-size: 28px;
                  line-height: 34px;
                  flex-grow: 1;
                }
              }
              ul {
                padding: 20px 10px 18px;
                li {
                  font-size: 16px;
                  line-height: 20px;
                }
              }
            }
            .usdt {
              width: 48.5%;
              .wallet-title {
                padding: 19px 10px;
                img {
                  width: 28px;
                }
                h3 {
                  font-size: 18px;
                  margin-left: 7px;
                  line-height: 22px;
                }
              }
              .balance {
                padding: 19px 13px;
                h2 {
                  font-size: 28px;
                  line-height: 34px;
                  flex-grow: 1;
                }
              }
              ul {
                padding: 20px 10px 18px;
                li {
                  &::after {
                    position: absolute;
                    top: 50%;
                    right: -18px;
                  }
                  a {
                    font-size: 16px;
                    line-height: 20px;
                    padding-left: 20px;
                    i {
                      left: 0;
                      top: 50%;
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
          .referral-exchange {
            .exchange {
              .title-exchange {
                padding: 18px 10px;
                h4 {
                  font-size: 18px;
                  margin-left: 8px;
                  line-height: 22px;
                }
              }
              p {
                padding: 20px 25px 5px;
              }
              .course {
                margin-bottom: 19px;
              }
              form {
                padding: 0 14px;
                .inputs-content {
                  margin-bottom: 2px;
                  padding-bottom: 15px;
                }
                .inputs {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .input {
                    width: 44.5%;
                    margin-bottom: 0;
                    padding: 10px 15px;
                    div {
                      input {
                        font-size: 16px;
                        line-height: 19px;
                      }
                    }
                    strong {
                      text-align: right;
                    }
                  }
                }
                .change {
                  top: 40%;
                }
                button {
                  height: 56px;
                  font-size: 16px;
                  line-height: 20px;
                  i {
                    margin-right: 7px;
                  }
                }
              }
            }
            .referral-program {
              .referral-title {
                display: flex;
                align-items: center;
                padding: 8px 40px 8px 28px;
                justify-content: space-between;
                h4 {
                  font-size: 18px;
                  line-height: 22px;
                  i {
                    font-size: 21px;
                    margin-right: 8px;
                  }
                }
                .name {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  img {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                  }
                  div {
                    margin-left: 10px;
                    span {
                      display: block;
                      font-size: 14px;
                      color: #96979e;
                      line-height: 17px;
                      margin-bottom: 5px;
                      font-weight: normal;
                    }
                    p {
                      font-size: 16px;
                      color: #2b1a4f;
                      font-weight: 600;
                      line-height: 20px;
                    }
                  }
                }
              }
              .referral-content {
                padding: 18px 14px 6px;
                .program-title {
                  margin-bottom: 11px;
                  ul {
                    li {
                      font-size: 12px;
                      line-height: 14px;
                      &:nth-child(1) {
                        width: 10%;
                      }
                      &:nth-child(2) {
                        width: 24%;
                      }
                      &:nth-child(3) {
                        width: 26%;
                      }
                      &:nth-child(4) {
                        width: 40%;
                        display: block;
                        padding-right: 0;
                      }
                    }
                  }
                }
                .program-content {
                  .program-item {
                    .item {
                      padding: 13px 0 11px;
                      .lvl {
                        width: 10%;
                      }
                      .participants {
                        width: 24%;
                      }
                      .profitability {
                        width: 26%;
                        padding-right: 15px;
                      }
                      .reward {
                        width: 40%;
                        margin: 0;
                        padding: 0;
                        text-align: left;
                        padding-right: 30px;
                        background: transparent;
                        span {
                          display: none;
                        }
                        .reward-items {
                          display: flex;
                          .reward-item {
                            margin-right: 5px;
                            &:last-child {
                              margin-right: 0;
                            }
                          }
                        }
                      }
                      .open-drop {
                        top: 13px;
                      }
                    }
                    .drop {
                      margin: 0;
                      padding: 14px 0 10px 20px;
                      .drop-content {
                        max-height: 305px;
                        ul {
                          display: flex;
                          margin-bottom: 12px;
                          li {
                            font-size: 12px;
                            color: #9999a5;
                            line-height: 15px;
                            font-weight: normal;
                            &:nth-child(1) {
                              width: 17.5%;
                            }
                            &:nth-child(2) {
                              width: 20%;
                            }
                            &:nth-child(3) {
                              width: 29%;
                            }
                            &:nth-child(4) {
                              width: 33.5%;
                            }
                          }
                        }
                        .drop-items {
                          margin-bottom: 0;
                          padding: 17px 0 14px;
                          &:last-child {
                            &::after {
                              display: block;
                            }
                          }
                          &::after {
                            top: 0;
                            background: rgba(136, 136, 136, 0.1);
                          }
                          .drop-item {
                            border: none;
                            margin-bottom: 0;
                            padding-bottom: 0;
                            &:nth-child(1) {
                              width: 17.5%;
                            }
                            &:nth-child(2) {
                              width: 20%;
                            }
                            &:nth-child(3) {
                              width: 29%;
                            }
                            &:nth-child(4) {
                              width: 33.5%;
                            }
                            i {
                              display: none;
                            }
                          }
                        }
                      }
                      .mCSB_inside > .mCSB_container {
                        margin-right: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .investment {
          .finance {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            justify-content: space-between;
          }
          .staking {
            width: 48.7%;
            margin-bottom: 18px;
            .staking-title {
              padding: 18px 10px;
              i {
                font-size: 18px;
              }
              h4 {
                font-size: 18px;
                line-height: 22px;
              }
            }
            .staking-content {
              padding: 15px 19px 8px;
              .balance {
                margin-bottom: 19px;
                padding-bottom: 12px;
                .balance-item {
                  span {
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 3px;
                  }
                  strong {
                    font-size: 18px;
                    line-height: 22px;
                  }
                  a {
                    font-size: 16px;
                    line-height: 26px;
                    padding-left: 18px;
                  }
                }
              }
              form {
                .items {
                  .item {
                    label {
                      font-size: 14px;
                      line-height: 18px;
                      margin-bottom: 10px;
                    }
                    .input {
                      input {
                        height: 51px;
                        font-size: 18px;
                        line-height: 22px;
                      }
                      strong {
                        font-size: 14px;
                        line-height: 17px;
                        cursor: pointer;
                        opacity: 0.5;
                        transition: all 0.3s;
                        &:hover {
                          opacity: 1;
                        }
                      }
                      span {
                        right: 16px;
                        font-size: 14px;
                        line-height: 17px;
                      }
                    }
                  }
                }
                .profitability {
                  font-size: 14px;
                  border-top: none;
                  line-height: 17px;
                  padding: 4px 10px 19px;
                  .info {
                    i {
                      font-size: 16px;
                      margin-left: 6px;
                    }
                    .info-window {
                      top: -34px;
                      left: 42px;
                      &:after {
                        top: 30px;
                        left: -12px;
                        transform: rotate(0deg);
                      }
                    }
                  }
                }
                button {
                  height: 40px;
                  font-size: 17px;
                  line-height: 20px;
                  i {
                    font-size: 16px;
                    margin-right: 6px;
                  }
                }
              }
            }
          }
          .my-balance {
            width: 48.7%;
            height: auto;
            padding: 15px 22px;
            margin-bottom: 18px;
            svg {
              top: 30px;
              width: 104%;
            }
            .today {
              p {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 7px;
              }
              h5 {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 5px;
                span {
                  font-size: 14px;
                  line-height: 17px;
                }
              }
              i {
                font-size: 14px;
              }
            }
            .overall {
              p {
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 6px;
              }
              a {
                font-size: 16px;
                line-height: 20px;
                padding: 21px 15px 6px;
                i {
                  font-size: 14px;
                }
              }
            }
          }
          .history-investing {
            width: 100%;
            .investing-title {
              padding: 16px 10px;
              h4 {
                font-size: 18px;
                line-height: 22px;
                margin-left: 6px;
              }
              i {
                font-size: 22px;
              }
            }
            .history-content {
              padding: 16px 19px 8px;
              .history-title {
                display: block;
                margin-bottom: 10px;
                ul {
                  display: flex;
                  li {
                    font-size: 12px;
                    color: #9999a5;
                    line-height: 15px;
                    font-weight: normal;
                    &:nth-child(1) {
                      width: 30%;
                    }
                    &:nth-child(2) {
                      width: 40%;
                    }
                    &:nth-child(3) {
                      width: 30%;
                    }
                  }
                }
              }
              .history-items {
                .history-item {
                  padding: 12px 0;
                  .data {
                    width: 30%;
                    span {
                      display: none;
                    }
                  }
                  .number {
                    width: 40%;
                    display: block;
                    padding-right: 20px;
                    strong {
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 20px;
                      color: #7036b6;
                      margin-right: 1px;
                      display: inline-block;
                    }
                    i {
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 18px;
                      color: #9999a5;
                    }
                  }
                  .accruals {
                    width: 30%;
                    span {
                      display: none;
                    }
                  }
                  .number-mob {
                    display: none;
                  }
                }
              }
            }
          }
          .deposits {
            .deposits-title {
              padding: 17px 10px;
              i {
                font-size: 20px;
              }
              h4 {
                font-size: 18px;
                line-height: 22px;
              }
            }
            .deposits-content {
              padding: 7px 5px 18px;
              .deposits-cap {
                padding: 5px 18px;
                li {
                  &:nth-child(1) {
                    width: 24%;
                  }
                  &:nth-child(2) {
                    width: 25%;
                  }
                  &:nth-child(3) {
                    width: 27%;
                  }
                  &:nth-child(4) {
                    width: 24%;
                  }
                }
              }
              .deposits-items {
                .deposits-item {
                  margin: 0 0 6px;
                  padding: 15px 30px 17px 19px;
                  .rate {
                    width: 24%;
                  }
                  .period {
                    width: 26%;
                  }
                  .left {
                    width: 27%;
                  }
                  .data {
                    width: 23%;
                  }
                  .open {
                    right: 14px;
                  }
                }
              }
            }
          }
        }
        .farming {
					.farming-title {
						h4 {
							font-size: 18px;
							font-weight: 600;
							line-height: 22px;
							i {
								font-size: 28px;
								margin-right: 8px;
								color: #7036b6;
							}
						}
						ul {
							li {
								a {
									font-size: 16px;
									line-height: 24px;
									padding: 16px 10px;
									i {
										font-size: 20px;
									}
								}
							}
						}
					}
          .finance-content {
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;
          }
          .staking {
            width: 48.7%;
            margin-bottom: 20px;
            .staking-title {
              padding: 18px 10px;
              .info {
                i {
                  font-size: 18px;
                }
              }
            }
            .staking-content {
              padding: 15px 19px 8px;
              .balance {
                margin-bottom: 19px;
                padding-bottom: 12px;
                .balance-item {
                  span {
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 3px;
                  }
                  strong {
                    font-size: 18px;
                    line-height: 22px;
                  }
                  a {
                    font-size: 16px;
                    line-height: 26px;
                    padding-left: 18px;
                  }
                }
              }
              form {
                .items {
                  .item {
                    label {
                      font-size: 14px;
                      line-height: 18px;
                      margin-bottom: 10px;
                    }
                    .input {
                      input {
                        height: 51px;
                        font-size: 18px;
                        line-height: 22px;
                      }
                      strong {
                        font-size: 14px;
                        line-height: 17px;
                      }
                      span {
                        right: 16px;
                        font-size: 14px;
                        line-height: 17px;
                      }
                    }
                  }
                }
                .profitability {
                  font-size: 14px;
                  border-top: none;
                  line-height: 17px;
                  padding: 4px 10px 19px;
                  .info {
                    i {
                      font-size: 16px;
                      margin-left: 6px;
                    }
                    .info-window {
                      top: -34px;
                      left: 42px;
                      &:after {
                        top: 30px;
                        left: -12px;
                        transform: rotate(0deg);
                      }
                    }
                  }
                }
                .farm {
                  height: 40px;
                  font-size: 17px;
                  line-height: 20px;
                  i {
                    font-size: 16px;
                    margin-right: 6px;
                  }
                }
              }
            }
          }
          .history-investing {
            width: 100%;
            .investing-title {
              padding: 16px 10px;
              h4 {
                font-size: 18px;
                line-height: 22px;
                margin-left: 6px;
              }
              i {
                font-size: 22px;
              }
            }
            .history-content {
              overflow-x: initial;
              padding: 16px 19px 8px;
              .history-title {
                width: 100%;
                display: block;
                margin-bottom: 10px;
                ul {
                  display: flex;
                  li {
                    font-size: 12px;
                    color: #9999a5;
                    line-height: 15px;
                    font-weight: normal;
                  }
                }
              }
              .history-items {
                width: 100%;
                .history-item {
                  padding: 11px 0 9px;
                }
              }
            }
          }
        }
				.rebalancing {
					.rebalancing-content {
						flex-wrap: wrap;
						display: flex;
						justify-content: space-between;
						.rebalancing_staking{
							width: 49%;
						}
					}
					.rebalancing_investing_items{
						flex-wrap: wrap;
						display: flex;
						justify-content: space-between;
					}
					.rebalancing_investing{
						width: 49%;
					}
				}
      }
    }
  }
  @media (min-width: 1024px) {
    .header {
      padding: 10px 20px;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
      .logo {
        position: static;
        transform: translate(0, 0);
      }
      .wallet {
        display: flex;
        padding-right: 0;
        margin-right: 72px;
        align-items: center;
        img {
          width: 42px;
        }
        .wallet-text {
          display: flex;
          margin-left: 10px;
          position: relative;
          padding-right: 47px;
          flex-direction: column;
          span {
            font-size: 14px;
            color: #7036b6;
            font-weight: 600;
            line-height: 17px;
            margin-bottom: 5px;
            display: inline-block;
          }
          strong {
            font-size: 16px;
            color: #2b1a4f;
            font-weight: 600;
            line-height: 20px;
            display: inline-block;
          }
          a {
            right: 0;
            top: 50%;
            font-size: 30px;
            color: #7036b6;
            position: absolute;
            transform: translate(0, -50%);
          }
        }
      }
      .referral-link {
        top: 0;
        left: 202px;
        width: auto;
        bottom: initial;
        text-align: left;
        padding: 9px 18px 8px 19px;
        .link {
          justify-content: flex-start;
          p {
            max-width: 360px;
          }
          .share {
            display: none;
          }
          .copy {
            display: block;
            transform: translate(0, -2px);
            i {
              font-size: 18px;
              color: #7036b6;
            }
          }
        }
      }
    }
    .platforma-content {
      .content-tabs {
        .dashboard {
          .wallets {
            margin-bottom: 20px;
            .coin {
              width: 32%;
              margin-bottom: 0;
            }
            .token {
              width: 32%;
              margin-bottom: 0;
            }
            .usdt {
              width: 32%;
              margin-bottom: 0;
            }
          }
          .referral-exchange {
            display: flex;
            align-items: flex-start;
            flex-direction: row-reverse;
            justify-content: space-between;
            .exchange {
              width: 32%;
              margin-bottom: 0;
              .title-exchange {
                i {
                  font-size: 18px;
                }
              }
              p {
                padding: 14px 20px 5px;
              }
              .course {
                margin-bottom: 15px;
              }
              form {
                padding: 0 9px;
                .inputs-content {
                  margin-bottom: 4px;
                  padding-bottom: 10px;
                }
                .inputs {
                  display: flex;
                  flex-direction: column;
                  .input {
                    width: 100%;
                    padding: 10px 15px;
                    margin-bottom: 20px;
                  }
                }
                .change {
                  top: 47%;
                }
                button {
                  height: 55px;
                }
              }
            }
            .referral-program {
              width: 66%;
              .referral-title {
                padding: 8px 20px 8px 29px;
              }
              .referral-content {
                padding: 17px 14px 6px;
                .program-title {
                  ul {
                    li {
                      &:nth-child(1) {
                        width: 10%;
                      }
                      &:nth-child(4) {
                        width: 40%;
                      }
                    }
                  }
                }
                .program-content {
                  .program-item {
                    .item {
                      .lvl {
                        width: 10%;
                      }
                      .reward {
                        width: 40%;
                      }
                    }
                    .drop {
                      .drop-content {
                        ul {
                          li {
                            &:nth-child(1) {
                              width: 18%;
                            }
                            &:nth-child(2) {
                              width: 21.5%;
                            }
                            &:nth-child(3) {
                              width: 26.5%;
                            }
                            &:nth-child(4) {
                              width: 34%;
                            }
                          }
                        }
                        .drop-items {
                          .drop-item {
                            &:nth-child(1) {
                              width: 18%;
                            }
                            &:nth-child(2) {
                              width: 21.5%;
                            }
                            &:nth-child(3) {
                              width: 26.5%;
                            }
                            &:nth-child(4) {
                              width: 34%;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .investment {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .finance {
            width: 66%;
          }
          .staking {
            margin-bottom: 21px;
            .staking-content {
              padding: 15px 13px 8px;
              .balance {
                margin-bottom: 14px;
              }
              form {
                .items {
                  margin-bottom: 6px;
                  .item {
                    margin-bottom: 15px;
                    label {
                      font-size: 12px;
                      line-height: 18px;
                      margin-bottom: 6px;
                    }
                  }
                }
                .profitability {
                  font-size: 12px;
                  line-height: 13px;
                  padding: 4px 10px 16px;
                }
              }
            }
          }
          .my-balance {
            margin-bottom: 21px;
            .overall {
              a {
                padding: 18px 15px 6px;
              }
            }
          }
          .history-investing {
            width: 32%;
            .investing-title {
              padding: 16px 30px;
              justify-content: flex-start;
            }
            .history-content {
              padding: 15px 13px 8px;
            }
          }
          .deposits {
            width: 100%;
            .deposits-content {
              padding: 8px 5px 9px;
              .deposits-cap {
                padding: 5px 0px 6px;
                li {
                  &:nth-child(1) {
                    width: 20%;
                  }
                  &:nth-child(2) {
                    width: 20%;
                  }
                  &:nth-child(3) {
                    width: 20%;
                  }
                  &:nth-child(4) {
                    width: 20%;
                  }
                  &:nth-child(5) {
                    width: 20%;
                  }
                }
              }
              .deposits-items {
                .deposits-item {
                  margin: 0 -11px 6px;
                  padding: 15px 12px 17px 10px;
                  .rate {
                    width: 20%;
                  }
                  .period {
                    width: 20%;
                  }
                  .left {
                    width: 20%;
                  }
                  .data {
                    width: 20%;
                  }
                  .profit {
                    width: 20%;
                  }
                  .open {
                    right: 4px;
                  }
                }
              }
            }
          }
        }
        .investment.active {
          display: flex;
        }
        .farming {
          .staking {
            margin-bottom: 21px;
            .staking-content {
              padding: 15px 13px 8px;
              .balance {
                margin-bottom: 14px;
              }
              form {
                .items {
                  margin-bottom: 6px;
                  .item {
                    margin-bottom: 15px;
                    label {
                      font-size: 12px;
                      line-height: 18px;
                      margin-bottom: 6px;
                    }
                  }
                }
                .profitability {
                  font-size: 12px;
                  line-height: 13px;
                  padding: 4px 10px 16px;
                }
              }
            }
          }
          .history-investing {
            .investing-title {
              padding: 16px 30px;
              justify-content: flex-start;
            }
            .history-content {
              padding: 15px 18px 10px;
              .history-title {
                ul {
                  li {
                    &:nth-child(1) {
                      width: 5%;
                    }
                    &:nth-child(2) {
                      width: 12%;
                    }
                    &:nth-child(3) {
                      width: 15%;
                    }
                    &:nth-child(4) {
                      width: 20%;
                    }
                    &:nth-child(5) {
                      width: 15%;
                    }
                    &:nth-child(6) {
                      width: 15%;
                    }
                    &:nth-child(7) {
                      width: 18%;
                    }
                  }
                }
              }
              .history-items {
                .history-item {
                  padding: 12px 0 8px;
                  .data {
                    width: 5%;
                  }
                  .coins {
                    width: 12%;
                  }
                  .balance {
                    width: 15%;
                  }
                  .period {
                    width: 20%;
                  }
                  .per-day {
                    width: 15%;
                  }
                  .profit {
                    width: 15%;
                  }
                  .withdraw {
                    width: 18%;
                  }
                }
              }
            }
          }
        }
				.rebalancing {
					.rebalancing_history-investing {
						.investing-title {
							padding: 16px 30px;
							justify-content: flex-start;
						}
					}
				}
      }
    }
  }
  @media (min-width: 1320px) {
    height: 100vh;

    .header {
      padding: 14px 20px;
      .open-nav {
        display: none;
      }
      .referral-link {
        left: 260px;
        padding: 12px 20px 12px 18px;
      }
      .wallet {
        margin-right: 0;
        .wallet-text {
          padding-right: 50px;
        }
      }
    }
    .platforma-content {
      display: flex;
      position: relative;
      padding-left: 260px;
      align-items: flex-start;
      justify-content: space-between;
      .platforma-nav {
        top: 0;
        left: 0;
        z-index: 3;
        width: 240px;
        height: calc(100vh - 140px);
        display: block;
        padding: 15px 0;
        position: absolute;
        border-radius: 25px;
        background: #ffffff;
        border: 1px solid rgba(112, 54, 182, 0.1);
        nav {
          ul {
            a {
              font-size: 16px;
              font-weight: 600;
              line-height: 26px;
              color: #2b1a4f;
              position: relative;
              transition: all 0.4s;
              display: list-item;
              cursor: pointer;
              padding: 17px 20px 17px 57px;
              &:hover {
                &::after {
                  height: 54px;
                }
              }
              &:nth-child(5) {
                i {
                  font-size: 28px;
                }
              }
              &:nth-child(6) {
                i {
                  font-size: 20px;
                }
              }
              &::after {
                content: "";
                top: 50%;
                height: 0;
                width: 4px;
                right: -1px;
                border-radius: 5px;
                position: absolute;
                background: #2b1a4f;
                transform: translate(0, -50%);
                transition: all 0.4s;
              }
              i {
                left: 19px;
                top: 50%;
                font-size: 25px;
                color: #2b1a4f;
                position: absolute;
                transform: translate(0, -50%);
                transition: all 0.4s;
              }
              span {
                left: 57px;
                bottom: 5px;
                display: block;
                font-size: 12px;
                color: #9999a5;
                line-height: 10px;
                position: absolute;
                font-weight: normal;
              }
            }
            a.active {
              &::after {
                height: 54px;
                background: #7036b6;
              }
              color: #7036b6;
              i {
                color: #7036b6;
              }
            }
            a.on_active {
              &:hover {
                &::after {
                  height: 0;
                }
              }
              color: #9999a5;
              i {
                color: #9999a5;
              }
            }
          }
        }
        .help {
          z-index: 2;
          //left: 70px;
          //bottom: 17px;
          margin-top: 20px;
          margin-left: 19px;
          font-size: 16px;
          font-weight: 500;
          color: #cd2576;
          line-height: 22px;
          padding-left: 38px;
          position: relative;
          display: block;
          &:hover {
            i {
              transform: translate(0, -50%) rotate(360deg);
            }
          }
          i {
            left: 0;
            top: 45%;
            font-size: 28px;
            color: #cd2576;
            position: absolute;
            transform: translate(0, -50%);
            transition: all 0.6s;
          }
        }
        .languages {
          z-index: 2;
          left: 18px;
          bottom: 20px;
          font-size: 18px;
          color: #2b1a4f;
          line-height: 22px;
          padding-left: 38px;
          position: absolute;
          font-weight: normal;
          transition: all 0.3s;
          &:hover {
            color: #cd2576;
          }
          img {
            left: 0;
            top: 45%;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            position: absolute;
            transform: translate(0, -50%);
          }
        }
      }
      .content-tabs {
        .dashboard {
          .wallets {
            .coin {
              .balance {
                h2 {
                  transform: translate(0, -8px);
                  flex-grow: 1;
                  i {
                    bottom: -16px;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }
            }
            .usdt {
              ul {
                li {
                  margin: 0 30px;
                  &::after {
                    right: -30px;
                  }
                }
              }
            }
          }
          .referral-exchange {
            .exchange {
              form {
                .inputs {
                  .input {
                    padding: 10px 20px;
                  }
                }
              }
            }
            .referral-program {
              width: 65.5%;
              .referral-title {
                padding: 8px 29px 8px 29px;
              }
              .referral-content {
                .program-title {
                  ul {
                    li {
                      &:nth-child(1) {
                        width: 10%;
                      }
                      &:nth-child(4) {
                        width: 40%;
                      }
                    }
                  }
                }
                .program-content {
                  .program-item {
                    .item {
                      .lvl {
                        width: 10%;
                      }
                      .reward {
                        width: 40%;
                      }
                    }
                    .drop {
                      .drop-content {
                        ul {
                          li {
                            &:nth-child(1) {
                              width: 18.5%;
                            }
                            &:nth-child(2) {
                              width: 21%;
                            }
                            &:nth-child(3) {
                              width: 30.5%;
                            }
                            &:nth-child(4) {
                              width: 30%;
                            }
                          }
                        }
                        .drop-items {
                          .drop-item {
                            &:nth-child(1) {
                              width: 18.5%;
                            }
                            &:nth-child(2) {
                              width: 21%;
                            }
                            &:nth-child(3) {
                              width: 30.5%;
                            }
                            &:nth-child(4) {
                              width: 30%;
                            }
                          }
                        }
                      }
                      .mCSB_scrollTools .mCSB_draggerContainer {
                        right: 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .investment {
          .staking {
            margin-bottom: 18px;
            .staking-content {
              padding: 15px 20px 8px;
              .balance {
                margin-bottom: 19px;
              }
              form {
                .items {
                  .item {
                    margin-bottom: 19px;
                    label {
                      font-size: 14px;
                      line-height: 18px;
                      margin-bottom: 11px;
                    }
                  }
                }
                .profitability {
                  font-size: 14px;
                  line-height: 17px;
                  padding: 4px 10px 20px;
                }
              }
            }
          }
          .my-balance {
            margin-bottom: 18px;
          }
          .history-investing {
            .history-content {
              padding: 16px 10px 8px 19px;
            }
          }
          .deposits {
            .deposits-content {
              padding: 8px 14px 9px;
              .deposits-items {
                .deposits-item {
                  margin: 0 -20px 6px;
                  padding: 15px 16px 17px 20px;
                  .open {
                    right: 9px;
                  }
                }
              }
            }
          }
        }
        .farming {
          .staking {
            width: 49%;
            margin-bottom: 18px;
            .staking-content {
              padding: 15px 20px 8px;
              .balance {
                margin-bottom: 19px;
              }
              form {
                .items {
                  .item {
                    margin-bottom: 19px;
                    label {
                      font-size: 14px;
                      line-height: 18px;
                      margin-bottom: 11px;
                    }
                  }
                }
                .profitability {
                  font-size: 14px;
                  line-height: 17px;
                  padding: 4px 10px 20px;
                }
              }
            }
          }
          .history-investing {
            .history-content {
              padding: 16px 10px 8px 19px;
              .history-title {
                ul {
                  li {
                    &:nth-child(1) {
                      width: 5%;
                    }
                    &:nth-child(2) {
                      width: 12%;
                    }
                    &:nth-child(3) {
                      width: 15%;
                    }
                    &:nth-child(4) {
                      width: 20%;
                    }
                    &:nth-child(5) {
                      width: 15%;
                    }
                    &:nth-child(6) {
                      width: 15%;
                    }
                    &:nth-child(7) {
                      width: 18%;
                    }
                  }
                }
              }
              .history-items {
                .history-item {
                  padding: 12px 0 8px;
                  .data {
                    width: 5%;
                  }
                  .coins {
                    width: 12%;
                  }
                  .balance {
                    width: 15%;
                  }
                  .period {
                    width: 20%;
                  }
                  .per-day {
                    width: 15%;
                  }
                  .profit {
                    width: 15%;
                  }
                  .withdraw {
                    width: 18%;
                  }
                }
              }
            }
          }
        }
				.rebalancing {
					.rebalancing-content {
						.rebalancing_staking {
							padding: 15px;
							.staking-content {
								form {
									label {
										font-size: 14px;
										line-height: 17px;
									}
									.input {
										input {
											font-size: 18px;
											line-height: 18px;
											text-align: right;
											padding: 10px 60px 10px 80px;
										}
									}
								}
								.balance {
									p {
										font-size: 16px;
										line-height: 20px;
										padding-bottom: 8px;
									}
								}
							}
						}
					}
					.rebalancing_history-investing {
						.rebalancing_investing {
							padding: 15px;
							form {
								.input {
									input {
										font-size: 18px;
										line-height: 18px;
										padding: 10px 60px 10px 140px;
									}
								}
							}
						}
					}
				}
      }
      .mCSB_scrollTools {
        right: 3px;
        width: 10px;
      }
      .mCustomScrollBox {
        margin-right: -20px;
      }
      .mCSB_inside > .mCSB_container {
        margin-right: 15px;
      }
      .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background: #7036b6;
      }
      .mCSB_scrollTools .mCSB_draggerRail {
        background: rgba(112, 54, 182, 0.1);
      }
    }
  }
  @media (min-width: 1600px) {
    padding: 40px 40px 24px;
    .header {
      padding: 20px 17px;
      margin-bottom: 40px;
      .logo {
        margin-left: 22px;
      }
      .referral-link {
        left: 360px;
        padding: 18px 18px 15px 18px;
        .link {
          p {
            font-size: 18px;
            max-width: 420px;
            line-height: 28px;
          }
          .copy {
            transform: translate(0, 0);
            i {
              font-size: 24px;
            }
          }
        }
      }
      .wallet {
        img {
          width: 47px;
        }
        .wallet-text {
          margin-left: 12px;
          padding-right: 53px;
          strong {
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
    }
    .platforma-content {
      padding-left: 362px;
      .platforma-nav {
        width: 322px;
        height: calc(100vh - 200px);
        overflow-y: auto;
        //min-height: 470px;
        nav {
          ul {
            a {
              padding: 23px 40px 23px 80px;
              font-size: 20px;
              line-height: 28px;
              i {
                left: 40px;
              }
              span {
                left: 80px;
                bottom: 13px;
              }
            }
          }
        }
        .help {
          //left: 40px;
          //bottom: 32px;
          font-size: 18px;
          line-height: 22px;
          margin-left: 40px;
          margin-top: 26px;
          display: block;
        }
        .languages {
          left: 40px;
          bottom: 25px;
          padding-left: 35px;
        }
      }
      .content-tabs {
        .dashboard {
          padding: 0 10px;
          .wallets {
            margin-bottom: 41px;
            .coin {
              width: 31.5%;
              .wallet-title {
                img {
                  width: 34px;
                }
                h3 {
                  font-size: 24px;
                  margin-left: 7px;
                  line-height: 30px;
                }
              }
              .balance {
                margin: 0 -11px;
                padding: 22px 30px;
                span {
                  left: 30px;
                  font-size: 14px;
                  line-height: 18px;
                }
                h2 {
                  font-size: 36px;
                  line-height: 44px;
                  transform: translate(0, -9px);
                  flex-grow: 1;
                  i {
                    font-size: 14px;
                  }
                }
                strong {
                  right: 30px;
                  font-size: 14px;
                  line-height: 18px;
                }
              }
              ul {
                padding: 26px 10px 24px;
                li {
                  a {
                    font-size: 18px;
                    line-height: 22px;
                    padding-left: 26px;
                    i {
                      top: 50%;
                      left: -2px;
                      font-size: 17px;
                    }
                  }
                }
              }
            }
            .token {
              width: 31.5%;
              .wallet-title {
                img {
                  width: 34px;
                }
                h3 {
                  font-size: 24px;
                  margin-left: 7px;
                  line-height: 30px;
                }
              }
              .balance {
                margin: 0 -11px;
                padding: 22px 30px;
                span {
                  left: 30px;
                  font-size: 14px;
                  line-height: 18px;
                }
                h2 {
                  font-size: 36px;
                  line-height: 44px;
                  flex-grow: 1;
                }
                strong {
                  right: 30px;
                  font-size: 14px;
                  line-height: 18px;
                }
              }
              ul {
                padding: 26px 10px 24px;
                li {
                  font-size: 18px;
                  line-height: 22px;
                }
              }
            }
            .usdt {
              width: 31.5%;
              .wallet-title {
                img {
                  width: 34px;
                }
                h3 {
                  font-size: 24px;
                  margin-left: 7px;
                  line-height: 30px;
                }
              }
              .balance {
                margin: 0 -11px;
                padding: 22px 30px;
                span {
                  left: 30px;
                  font-size: 14px;
                  line-height: 18px;
                }
                h2 {
                  font-size: 36px;
                  line-height: 44px;
                  flex-grow: 1;
                }
                strong {
                  right: 30px;
                  font-size: 14px;
                  line-height: 18px;
                }
              }
              ul {
                padding: 26px 10px 24px;
                li {
                  margin: 0 28px;
                  &::after {
                    right: -28px;
                  }
                  a {
                    font-size: 18px;
                    line-height: 22px;
                    padding-left: 26px;
                    i {
                      top: 50%;
                      left: -2px;
                      font-size: 17px;
                    }
                  }
                }
              }
            }
          }
          .referral-exchange {
            .exchange {
              width: 31.5%;
              .title-exchange {
                margin: 0 -11px;
                padding: 23px 10px;
                i {
                  font-size: 22px;
                }
                h4 {
                  font-size: 20px;
                  margin-left: 8px;
                  line-height: 24px;
                }
              }
              p {
                font-size: 16px;
                line-height: 24px;
                padding: 16px 20px 8px;
              }
              .course {
                font-size: 18px;
                line-height: 24px;
              }
              form {
                padding: 0 20px 0 16px;
                .inputs-content {
                  margin-bottom: 4px;
                  padding-bottom: 18px;
                }
                .inputs {
                  .input {
                    padding: 14px 22px;
                    margin-bottom: 30px;
                    div {
                      label {
                        font-size: 14px;
                        line-height: 16px;
                      }
                      input {
                        height: 24px;
                        font-size: 18px;
                        line-height: 22px;
                      }
                    }
                  }
                }
                .change {
                  top: 45%;
                }
                button {
                  height: 56px;
                  font-size: 18px;
                  line-height: 23px;
                  i {
                    font-size: 18px;
                  }
                }
              }
            }
            .referral-program {
              width: 65.7%;
              .referral-title {
                margin: 0 -11px;
                padding: 13px 30px;
                h4 {
                  font-size: 20px;
                  line-height: 22px;
                  i {
                    font-size: 22px;
                    margin-right: 12px;
                  }
                }
                .name {
                  img {
                    width: 44px;
                    height: 44px;
                  }
                  div {
                    p {
                      font-size: 18px;
                    }
                  }
                }
              }
              .referral-content {
                padding: 20px 20px 10px;
                .program-title {
                  margin-bottom: 14px;
                  ul {
                    li {
                      font-size: 14px;
                      line-height: 16px;
                      &:nth-child(1) {
                        width: 10%;
                      }
                      &:nth-child(2) {
                        width: 26.5%;
                      }
                      &:nth-child(3) {
                        width: 20.5%;
                      }
                      &:nth-child(4) {
                        width: 43%;
                      }
                    }
                  }
                }
                .program-content {
                  .program-item {
                    .item {
                      padding: 15px 0;
                      .lvl {
                        width: 10%;
                        font-size: 18px;
                        line-height: 22px;
                      }
                      .participants {
                        width: 26.5%;
                        font-size: 18px;
                        line-height: 22px;
                      }
                      .profitability {
                        width: 20.5%;
                        font-size: 18px;
                        line-height: 22px;
                      }
                      .reward {
                        width: 43%;
                        font-size: 18px;
                        line-height: 22px;
                        strong {
                          font-size: 18px;
                          line-height: 22px;
                        }
                        i {
                          font-size: 14px;
                          line-height: 17px;
                        }
                        .reward-items {
                          .reward-item {
                            margin-right: 15px;
                          }
                        }
                      }
                    }
                    .drop {
                      padding: 15px 0 10px 20px;
                      .drop-content {
                        ul {
                          margin-bottom: 9px;
                          li {
                            font-size: 14px;
                            line-height: 17px;
                            &:nth-child(1) {
                              width: 19%;
                            }
                            &:nth-child(2) {
                              width: 28%;
                            }
                            &:nth-child(3) {
                              width: 28.5%;
                            }
                            &:nth-child(4) {
                              width: 24.5%;
                            }
                          }
                        }
                        .drop-items {
                          .drop-item {
                            &:nth-child(1) {
                              width: 19%;
                            }
                            &:nth-child(2) {
                              width: 28%;
                            }
                            &:nth-child(3) {
                              width: 28.5%;
                            }
                            &:nth-child(4) {
                              width: 24.5%;
                            }
                          }
                        }
                      }
                      .mCSB_scrollTools .mCSB_draggerContainer {
                        right: 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .investment {
          padding: 0 10px;
          .finance {
            width: 65.6%;
          }
          .staking {
            width: 48%;
            margin-bottom: 40px;
            .staking-title {
              margin: 0 -10px;
              padding: 22px 15px;
              .info {
                i {
                  font-size: 22px;
                }
              }
              h4 {
                font-size: 20px;
                line-height: 24px;
              }
              span {
                right: 18px;
                i {
                  font-size: 22px;
                }
              }
            }
            .staking-content {
              padding: 22px 30px 2px;
              .balance {
                margin-bottom: 27px;
                .balance-item {
                  strong {
                    font-size: 20px;
                    line-height: 26px;
                  }
                  a {
                    font-size: 18px;
                    line-height: 28px;
                    padding-left: 20px;
                    i {
                      font-size: 15px;
                    }
                  }
                }
              }
              form {
                margin: 0 0 8px;
                .items {
                  .item {
                    margin-bottom: 21px;
                    .input {
                      input {
                        font-size: 18px;
                        line-height: 22px;
                        height: 60px;
                      }
                      strong {
                        left: 18px;
                      }
                      span {
                        right: 19px;
                      }
                    }
                    .days {
                      padding: 5px 20px;
                      li {
                        font-size: 18px;
                        line-height: 24px;
                      }
                    }
                  }
                }
                button {
                  height: 50px;
                  font-size: 20px;
                  line-height: 22px;
                  i {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
          .my-balance {
            width: 48%;
            padding: 18px 30px;
            margin-bottom: 40px;
            .today {
              top: 52px;
              left: 31px;
            }
            svg {
              top: 28px;
              width: 103%;
            }
            .overall {
              p {
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 10px;
              }
              h3 {
                font-size: 46px;
                line-height: 54px;
                margin-bottom: 6px;
              }
              span {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 15px;
              }
              .usd {
                margin-bottom: 25px;
              }
              a {
                font-size: 18px;
                line-height: 24px;
                i {
                  font-size: 18px;
                  margin-right: 9px;
                }
              }
            }
          }
          .history-investing {
            width: 31.5%;
            .investing-title {
              margin: 0 -10px;
              padding: 23px 29px;
              i {
                font-size: 24px;
              }
              h4 {
                font-size: 20px;
                line-height: 24px;
              }
            }
            .history-content {
              .history-title {
                margin-bottom: 14px;
                ul {
                  li {
                    font-size: 14px;
                    line-height: 18px;
                    &:nth-child(1) {
                      width: 30%;
                    }
                    &:nth-child(2) {
                      width: 40%;
                    }
                    &:nth-child(3) {
                      width: 30%;
                    }
                  }
                }
              }
              .history-items {
                .history-item {
                  padding: 15px 0;
                  .data {
                    width: 30%;
                    font-size: 18px;
                    line-height: 22px;
                  }
                  .number {
                    width: 40%;
                    strong {
                      font-size: 18px;
                      line-height: 22px;
                    }
                    i {
                      font-size: 14px;
                      line-height: 17px;
                    }
                  }
                  .accruals {
                    width: 30%;
                    font-size: 18px;
                    line-height: 22px;
                  }
                  /*.common-accruals {
                                        width: 20.5%;
                                        font-size: 18px;
                                        line-height: 22px;
                                    }*/
                }
              }
            }
          }
          .deposits {
            width: 100%;
            .deposits-title {
              margin: 0 -10px;
              padding: 20px 15px;
              i {
                font-size: 26px;
              }
              h4 {
                font-size: 20px;
                line-height: 24px;
              }
            }
            .deposits-content {
              padding: 16px 16px 16px;
              .deposits-cap {
                padding: 6px 16px 10px;
                li {
                  &:nth-child(1) {
                    width: 20%;
                  }
                  &:nth-child(2) {
                    width: 20%;
                  }
                  &:nth-child(3) {
                    width: 20%;
                  }
                  &:nth-child(4) {
                    width: 20%;
                  }
                  &:nth-child(5) {
                    width: 20%;
                  }
                }
              }
              .deposits-items {
                .deposits-item {
                  margin: 0 0 9px;
                  padding: 15px 35px 15px 16px;
                  .rate {
                    width: 20%;
                    font-size: 14px;
                    line-height: 18px;
                  }
                  .period {
                    width: 20%;
                    font-size: 14px;
                    line-height: 18px;
                  }
                  .left {
                    width: 20%;
                    font-size: 14px;
                    line-height: 18px;
                  }
                  .data {
                    width: 20%;
                    font-size: 14px;
                    line-height: 18px;
                  }
                  .profit {
                    width: 20%;
                    font-size: 14px;
                    line-height: 18px;
                  }
                  .open {
                    right: 18px;
                  }
                }
              }
            }
          }
        }
        .farming {
          padding: 0 10px;
          .farming-title {
            margin: 0 -10px 20px;
            ul {
              li {
                a {
                  font-size: 20px;
                  line-height: 26px;
                  padding: 25px 10px;
                  i {
                    font-size: 28px;
                  }
                }
              }
            }
          }
          .staking {
            width: 48.7%;
            margin-bottom: 40px;
            .staking-title {
              margin: 0 -10px;
              padding: 19px 15px;
              .info {
                i {
                  font-size: 22px;
                }
              }
              img {
                width: 34px;
              }
              h4 {
                font-size: 24px;
                line-height: 26px;
              }
              span {
                right: 18px;
                i {
                  font-size: 22px;
                }
              }
            }
            .staking-content {
              padding: 22px 30px 2px;
              .balance {
                margin-bottom: 24px;
                .balance-item {
                  strong {
                    font-size: 20px;
                    line-height: 26px;
                  }
                  a {
                    font-size: 18px;
                    line-height: 28px;
                    padding-left: 20px;
                    i {
                      font-size: 15px;
                    }
                  }
                }
              }
              form {
                margin: 0 0 8px;
                .items {
                  .item {
                    margin-bottom: 21px;
                    .input {
                      input {
                        font-size: 18px;
                        line-height: 22px;
                        height: 60px;
                      }
                      strong {
                        left: 18px;
                      }
                      span {
                        right: 19px;
                      }
                    }
                    .days {
                      padding: 5px 20px;
                      li {
                        font-size: 18px;
                        line-height: 24px;
                        .present-info {
                          top: 38px;
                          width: 300px;
                          padding: 15px 45px;
                          strong {
                            font-size: 14px;
                            line-height: 17px;
                            margin-bottom: 5px;
                          }
                          p {
                            font-size: 13px;
                            line-height: 19px;
                          }
                        }
                      }
                    }
                  }
                }
                .farm {
                  height: 50px;
                  font-size: 20px;
                  line-height: 22px;
                  i {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
          .history-investing {
            .investing-title {
              margin: 0 -10px;
              padding: 23px 29px;
              i {
                font-size: 24px;
              }
              h4 {
                font-size: 20px;
                line-height: 24px;
              }
            }
            .history-content {
              padding: 16px 28px 14px 28px;
              .history-title {
                margin-bottom: 14px;
                ul {
                  li {
                    font-size: 14px;
                    line-height: 18px;
                    &:nth-child(1) {
                      width: 5%;
                    }
                    &:nth-child(2) {
                      width: 12%;
                    }
                    &:nth-child(3) {
                      width: 15%;
                    }
                    &:nth-child(4) {
                      width: 20%;
                    }
                    &:nth-child(5) {
                      width: 15%;
                    }
                    &:nth-child(6) {
                      width: 15%;
                    }
                    &:nth-child(7) {
                      width: 18%;
                    }
                  }
                }
              }
              .history-items {
                .history-item {
                  padding: 14px 0 12px;
                  .data {
                    width: 5%;
                    font-size: 18px;
                    line-height: 22px;
                  }
                  .coins {
                    width: 12%;
                    img {
                      width: 26px;
                      margin-right: 6px;
                    }
                    i {
                      font-size: 18px;
                      line-height: 22px;
                    }
                  }
                  .balance {
                    width: 15%;
                    font-size: 18px;
                    line-height: 22px;
                  }
                  .period {
                    width: 20%;
                    strong {
                      font-size: 18px;
                      line-height: 22px;
                      span {
                        font-size: 18px;
                        line-height: 22px;
                        i {
                          font-size: 12px;
                          line-height: 15px;
                        }
                      }
                    }
                  }
                  .per-day {
                    width: 15%;
                    font-size: 18px;
                    line-height: 22px;
                  }
                  .profit {
                    width: 15%;
                    font-size: 18px;
                    line-height: 22px;
                  }
                  .withdraw {
                    width: 18%;
                    a {
                      font-size: 18px;
                      line-height: 22px;
                    }
                  }
                }
              }
            }
          }
        }
        .rebalancing {
          .rebalancing_history-investing {
            .investing-title {
              margin: 0 -10px 20px;
              padding: 23px 29px;
              i {
                font-size: 24px;
              }
              h4 {
                font-size: 20px;
                line-height: 24px;
              }
            }
          }
        }
      }
      .mCustomScrollBox {
        margin: 0 -30px 0 -10px;
      }
      .mCSB_inside > .mCSB_container {
        margin-right: 18px;
      }
      .mCSB_scrollTools {
        right: 0px;
        width: 10px;
      }
    }
  }
  @media (min-width: 1800px) {
    .platforma-content {
      .content-tabs {
        .dashboard {
          .wallets {
            .usdt {
              ul {
                li {
                  margin: 0 56px;
                  &::after {
                    right: -44px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*,
ol li,
ul li {
  margin: 0;
}

.popup-content .close-popup,
.popup-content .close-popup {
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.popup-content .close-popup:hover i,
.popup-content .close-popup:hover i,
.popup-content .close-popup:hover i,
.popup-content .close-popup:hover i,
.popup-content .close-popup:hover i,
.popup-content .close-popup:hover i,
.popup-content .close-popup:hover i {
  transform: rotate(360deg);
}
.popup-content .close-popup i,
.popup-content .close-popup i {
  font-size: 18px;
  display: block;
  transition: all 0.5s;
  color: #cd2576;
}
.popup-content .close-popup {
  top: 17px;
  right: 12px;
}

.popup-content .close-popup {
  position: absolute;
  z-index: 3;
  top: 17px;
  right: 12px;
  cursor: pointer;
}

.popup-content .close-popup i {
  font-size: 18px;
  display: block;
  transition: all 0.5s;
  color: #fff;
}
.popup-content .close-popup i {
  color: #fff;
}
.icon-check:before {
  content: "\e802";
}
.popup-replenishment
  .popup-content
  .replenishment-content
  form
  .days
  li.active {
  opacity: 1;
  color: #fff;
  background: #cd2576;
}
nav ul li[data-tabs="liquidity-pool"] {
  background: #f1ebf8;
}

nav ul li[data-tabs="liquidity-pool"]::after {
  display: none;
}

nav ul li.active[data-tabs="liquidity-pool"] {
  background: #7036b6;
}

nav ul li.active[data-tabs="liquidity-pool"] a,
nav ul li.active[data-tabs="liquidity-pool"] a i {
  color: #fff;
}
.liquidity-pool .info p i {
  font-size: 22px;
  position: absolute;
  top: 5px;
  left: 0;
  color: #cd2576;
}

.liquidity-pool .info p strong {
  font-weight: 700;
}

.liquidity-pool .info p a {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: inline-block;
  margin-top: 8px;
  text-decoration: underline;
  color: #cd2576;
}

.liquidity-pool .info .close-info {
  font-size: 12px;
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;
  cursor: pointer;
  color: #cd2576;
}

.liquidity-pool .pool .pool-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 25px;
}

.liquidity-pool .pool .pool-item .pool-after {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 105%;
  max-width: 105%;
  transform: translate(-50%, -50%);
}

.liquidity-pool .pool .pool-item .pool-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 299px;
  padding: 21px 15px 10px;
  justify-content: space-between;
}

.liquidity-pool .pool .pool-item .pool-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  text-align: center;
  color: #fff;
  justify-content: center;
}

.liquidity-pool .pool .pool-item .pool-content p i:hover {
  opacity: 1;
}

.liquidity-pool .pool .pool-item .pool-content .balance {
  text-align: center;
}

.liquidity-pool .pool .pool-item .pool-content .balance span {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.6;
  color: #fff;
}

.liquidity-pool .pool .pool-item .pool-content p i {
  font-size: 17px;
  display: inline-block;
  margin-left: 8px;
  transition: all 0.3s;
  opacity: 0.6;
  color: #fff;
}

.liquidity-pool {
  .pool {
    .pool-item {
      .pool-content {
        p {
          i:hover {
            &:hover {
              i {
                opacity: 1;
              }
              & + span.info {
                .info-window {
                  display: block;
                }
              }
            }
          }
          span.info {
            .info-window {
              top: 32px;
              right: 20px;
              width: 220px;
              display: none;
              padding: 12px;
              font-size: 13px;
              color: #7036b6;
              line-height: 19px;
              font-weight: normal;
              position: absolute;
              border-radius: 10px;
              background: #ffffff;
              border: 1px solid rgba(112, 54, 182, 0.5);
              box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
              &:after {
                content: "";
                position: absolute;
                top: -16px;
                right: 17px;
                width: 13px;
                height: 21px;
                background: url("../images/info-window.png") no-repeat 50% 50%;
                background-size: cover;
                transform: rotate(90deg);
              }
              strong {
                display: block;
                font-size: 14px;
                color: #7036b6;
                font-weight: 600;
                line-height: 17px;
                margin-bottom: 3px;
              }
            }
          }
        }
      }
    }
  }
}

.liquidity-pool .pool .pool-item .pool-content .balance h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  display: flex;
  margin-bottom: 8px;
  text-align: center;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.liquidity-pool .pool .pool-item .pool-content .balance h3 img {
  display: inline-block;
  width: 30px;
  margin-right: 14px;
}

.liquidity-pool .pool .pool-item .pool-content .balance i {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  display: inline-block;
  color: #fff;
}

.liquidity-pool .pool .pool-item .pool-content ul {
  display: flex;
  padding: 15px 0 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  align-items: center;
  justify-content: center;
}

.liquidity-pool .pool .pool-item .pool-content ul li {
  position: relative;
  width: 50%;
  text-align: center;
}

.liquidity-pool .pool .pool-item .pool-content ul li::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 22px;
  content: "";
  transform: translate(0, -50%);
  background: rgba(255, 255, 255, 0.2);
}

.liquidity-pool .pool .pool-item .pool-content ul li a {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  position: relative;
  display: inline-block;
  padding-left: 22px;
  text-align: center;
  color: #fff;
}

.liquidity-pool .pool .pool-item .pool-content ul li a i {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.liquidity-pool .monutes {
  border: 1px solid rgba(112, 54, 182, 0.1);
  border-top: none;
  border-radius: 25px;
  background: #fff;
}

.liquidity-pool .monutes .monutes-title {
  margin: 0 -6px;
  border-radius: 15px;
  background: #fae9f1;
}

.liquidity-pool .monutes .monutes-title h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  color: #2b1a4f;
  align-items: center;
}

.liquidity-pool .monutes .monutes-title h4 i {
  font-size: 20px;
  margin-right: 5px;
  color: #7036b6;
}

.liquidity-pool .monutes .monutes-title ul li {
  width: 50%;
  transition: all 0.3s;
  border-radius: 15px;
}

.liquidity-pool .monutes .monutes-title ul li a {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  width: 100%;
  padding: 13px 10px;
  transition: all 0.3s;
  text-align: center;
  color: #2b1a4f;
  justify-content: center;
}

.liquidity-pool .monutes .monutes-title ul li a i {
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
  transition: all 0.3s;
}

.liquidity-pool .monutes .monutes-title ul li.active {
  background: linear-gradient(180deg, #cd2576 0%, #8a164e 100%);
}

.liquidity-pool .monutes .monutes-title ul li.active a,
.liquidity-pool .monutes .monutes-title ul li.active a i {
  color: #fff;
}

.liquidity-pool .monutes .table {
  padding: 11px 8px 15px;
}

.liquidity-pool .monutes .table .monutes-table {
  display: none;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item {
  display: flex;
  margin-bottom: 12px;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(136, 136, 136, 0.15);
  flex-wrap: wrap;
  align-items: flex-start;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .lvl,
.liquidity-pool .monutes .table .monutes-content .monutes-item .status span {
  display: none;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .coin {
  width: 37%;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .coin i,
.liquidity-pool .monutes .table .monutes-content .monutes-item .contributed i,
.liquidity-pool .monutes .table .monutes-content .monutes-item .deposit i,
.liquidity-pool .monutes .table .monutes-content .monutes-item .profit i {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  display: block;
  margin-bottom: 8px;
  color: #9999a5;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .coin p {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  display: flex;
  color: #2b1a4f;
  align-items: center;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .coin p img {
  width: 22px;
  margin-right: 2px;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .contributed {
  font-size: 14px;
  line-height: 17px;
  width: 38%;
  color: #2b1a4f;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .deposit {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  width: 25%;
  color: #2b1a4f;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .profit {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  width: 87%;
  margin-top: 10px;
  padding: 6px 10px;
  text-align: center;
  color: #7036b6;
  border-radius: 15px;
  background: #f1ebf8;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .profit strong {
  font-weight: 700;
  color: #9999a5;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .status {
  width: 13%;
  margin-top: 28px;
  text-align: center;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .status i {
  font-size: 18px;
}
.liquidity-pool .info p {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
  padding-left: 29px;
  color: #2b1a4f;
}
.liquidity-pool .info {
  position: relative;
  margin-bottom: 15px;
  padding: 11px 13px 15px;
  border: 1px solid rgba(112, 54, 182, 0.1);
  border-radius: 15px;
  // background: #fff
}
.monutes {
  border: 1px solid rgba(112, 54, 182, 0.1);
  border-top: none;
  border-radius: 25px;
  background: #fff;
}
.monutes .table {
  padding: 11px 8px 15px;
}
nav ul li.active::after {
  height: 54px;
}

nav ul li.active a,
nav ul li.active a i {
  color: #7036b6;
}

nav ul li.on_active a,
nav ul li.on_active a i {
  color: #9999a5;
}
nav ul li[data-tabs="liquidity-pool"] {
  background: #f1ebf8;
}

nav ul li[data-tabs="liquidity-pool"]::after {
  display: none;
}

nav ul li.active[data-tabs="liquidity-pool"] {
  background: #7036b6;
}

nav ul li.active[data-tabs="liquidity-pool"] a,
nav ul li.active[data-tabs="liquidity-pool"] a i {
  color: #fff;
}
.pool-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 25px;
}
.monutes-title ul {
  display: flex;
}
.monutes-title {
  margin: 0 -6px;
  border-radius: 15px;
  background: #fae9f1;
}

.monutes-title h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  color: #2b1a4f;
  align-items: center;
}

.monutes-title h4 i {
  font-size: 20px;
  margin-right: 5px;
  color: #7036b6;
}

.monutes-title ul li {
  width: 50%;
  transition: all 0.3s;
  border-radius: 15px;
}

.monutes-title ul li a {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  width: 100%;
  padding: 13px 10px;
  transition: all 0.3s;
  text-align: center;
  color: #2b1a4f;
  justify-content: center;
}

.monutes-title ul li a i {
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
  transition: all 0.3s;
}

.monutes-title ul li.active {
  background: linear-gradient(180deg, #cd2576 0%, #8a164e 100%);
}

.monutes-title ul li.active a,
.monutes-title ul li.active a i {
  color: #fff;
}
.pool-item .pool-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 299px;
  padding: 21px 15px 10px;
  justify-content: space-between;
}

.pool-item .pool-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  text-align: center;
  color: #fff;
  justify-content: center;
}

.pool-item .pool-content p i {
  font-size: 17px;
  display: inline-block;
  margin-left: 8px;
  transition: all 0.3s;
  opacity: 0.6;
  color: #fff;
}

.pool-item .pool-content p i:hover {
  opacity: 1;
}

.pool-item .pool-content .balance {
  text-align: center;
}

.pool-item .pool-content .balance span {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.6;
  color: #fff;
}

.pool-item .pool-content .balance h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  display: flex;
  margin-bottom: 8px;
  text-align: center;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.pool-item .pool-content .balance h3 img {
  display: inline-block;
  width: 30px;
  margin-right: 14px;
}

.pool-item .pool-content .balance i {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  display: inline-block;
  color: #fff;
}

.pool-item .pool-content ul {
  display: flex;
  padding: 15px 0 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  align-items: center;
  justify-content: center;
}

.pool-item .pool-content ul li {
  position: relative;
  width: 50%;
  text-align: center;
}

.pool-item .pool-content ul li::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 22px;
  content: "";
  transform: translate(0, -50%);
  background: rgba(255, 255, 255, 0.2);
}

.pool-item .pool-content ul li a {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  position: relative;
  display: inline-block;
  padding-left: 22px;
  text-align: center;
  color: #fff;
}

.pool-item .pool-content ul li a i {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.pool-content ul li:last-child::after {
  display: none;
}
.pool-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 299px;
  padding: 21px 15px 10px;
  justify-content: space-between;
}

.pool-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  text-align: center;
  color: #fff;
  justify-content: center;
}

.pool-content p i {
  font-size: 17px;
  display: inline-block;
  margin-left: 8px;
  transition: all 0.3s;
  opacity: 0.6;
  color: #fff;
}

.pool-content p i:hover {
  opacity: 1;
}

.pool-content .balance {
  text-align: center;
}

.pool-content .balance span {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.6;
  color: #fff;
}

.pool-content .balance h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  display: flex;
  margin-bottom: 8px;
  text-align: center;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.pool-content .balance h3 img {
  display: inline-block;
  width: 30px;
  margin-right: 14px;
}

.pool-content .balance i {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  display: inline-block;
  color: #fff;
}

.pool-content ul {
  display: flex;
  padding: 15px 0 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  align-items: center;
  justify-content: center;
}

.pool-content ul li {
  position: relative;
  width: 50%;
  text-align: center;
}

.pool-content ul li::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 22px;
  content: "";
  transform: translate(0, -50%);
  background: rgba(255, 255, 255, 0.2);
}

.pool-content ul li a {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  position: relative;
  display: inline-block;
  padding-left: 22px;
  text-align: center;
  color: #fff;
}

.pool-content ul li a i {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.monutes-table {
  display: none;
}
.monutes-content .monutes-item {
  display: flex;
  margin-bottom: 12px;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(136, 136, 136, 0.15);
  flex-wrap: wrap;
  align-items: flex-start;
}

.monutes-content .monutes-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.monutes-content .monutes-item .lvl,
.monutes-content .monutes-item .status span {
  display: none;
}

.monutes-content .monutes-item .coin {
  width: 37%;
}

.monutes-content .monutes-item .coin i,
.monutes-content .monutes-item .contributed i,
.monutes-content .monutes-item .deposit i,
.monutes-content .monutes-item .profit i {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  display: block;
  margin-bottom: 8px;
  color: #9999a5;
}

.monutes-content .monutes-item .coin p {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  display: flex;
  color: #2b1a4f;
  align-items: center;
}

.monutes-content .monutes-item .coin p img {
  width: 22px;
  margin-right: 2px;
}

.monutes-content .monutes-item .contributed {
  font-size: 14px;
  line-height: 17px;
  width: 38%;
  color: #2b1a4f;
}

.monutes-content .monutes-item .deposit {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  width: 25%;
  color: #2b1a4f;
}

.monutes-content .monutes-item .profit {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  width: 87%;
  margin-top: 10px;
  padding: 6px 10px;
  text-align: center;
  color: #7036b6;
  border-radius: 15px;
  background: #f1ebf8;
}

.monutes-content .monutes-item .profit strong {
  font-weight: 700;
  color: #9999a5;
}

.monutes-content .monutes-item .status {
  width: 13%;
  margin-top: 28px;
  text-align: center;
}

.monutes-content .monutes-item .status i {
  font-size: 18px;
}

@media (min-width: 768px) {
  .popup-content .close-popup {
    top: 2px;
    right: -47px;
  }
  .monutes {
    font-size: 20px;
    margin-left: 8px;
  }
  .monutes {
    padding: 15px 9px;
    padding-top: 0px;
  }
  .monutes {
    font-size: 20px;
    margin-left: 8px;
  }
  .monutes-title ul li a i {
    font-size: 20px;
  }
  .monutes-title h4 i {
    font-size: 28px;
    margin-right: 8px;
    color: #7036b6;
  }

  .monutes-title ul li a {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 10px;
  }
  .monutes-title h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
  .pool-item {
    width: 47%;
    margin: 0 1.5% 20px;
  }

  .pool-item .pool-content {
    height: 350px;
    padding: 25px 20px 10px;
  }

  .monutes-title h4,
  .pool-item .pool-content p {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  .pool-item .pool-content p i {
    font-size: 20px;
    margin-left: 8px;
  }

  .pool-item .pool-content .balance span {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  .pool-item .pool-content .balance h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
  }

  .pool-item .pool-content .balance h3 img {
    width: 38px;
  }

  .pool-item .pool-content .balance i {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    opacity: 0.6;
    color: #f8f5fb;
  }
  .pool-content {
    height: 350px;
    padding: 25px 20px 10px;
  }

  .monutes-title h4,
  .pool-content p {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  .pool-content p i {
    font-size: 20px;
    margin-left: 8px;
  }

  .pool-content .balance span {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  .pool-content .balance h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
  }

  .pool-content .balance h3 img {
    width: 38px;
  }

  .pool-content .balance i {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    opacity: 0.6;
    color: #f8f5fb;
  }

  .monutes-content .monutes-item .coin i,
  .monutes-content .monutes-item .contributed i,
  .monutes-content .monutes-item .deposit i,
  .monutes-content .monutes-item .profit i {
    display: none;
  }
  .monutes-table ul {
    display: flex;
  }
  .monutes-table {
    display: block;
    margin-bottom: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(136, 136, 136, 0.15);
  }

  .monutes-table ul li {
    font-size: 12px;
    line-height: 15px;
    color: #9999a5;
  }

  .monutes-table ul li:nth-child(1) {
    width: 8%;
  }

  .monutes-table ul li:nth-child(2) {
    width: 15%;
  }

  .monutes-table ul li:nth-child(3) {
    width: 18%;
  }

  .monutes-table ul li:nth-child(4) {
    width: 15%;
  }

  .monutes-table ul li:nth-child(5) {
    width: 23%;
  }

  .monutes-table ul li:nth-child(6) {
    width: 21%;
  }
  .monutes-content .monutes-item {
    margin-bottom: 12px;
    padding-bottom: 12px;
    align-items: center;
  }

  .monutes-content .monutes-item .lvl {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    display: block;
    width: 8%;
    color: #7036b6;
  }

  .monutes-content .monutes-item .coin {
    width: 15%;
  }

  .monutes-content .monutes-item .coin p {
    font-weight: 500;
  }

  .monutes-content .monutes-item .coin p img {
    width: 20px;
    margin-right: 2px;
  }

  .monutes-content .monutes-item .contributed {
    font-weight: 600;
    width: 18%;
  }

  .monutes-content .monutes-item .deposit {
    font-weight: 500;
    width: 15%;
  }

  .monutes-content .monutes-item .profit {
    font-size: 14px;
    width: 23%;
    margin: 0;
    padding: 0;
    text-align: left;
    background: 0 0;
  }

  .monutes-content .monutes-item .profit strong {
    font-weight: 400;
  }

  .monutes-content .monutes-item .status {
    position: relative;
    width: 21%;
    margin: 0;
    padding: 0 0 0 22px;
    text-align: left;
  }

  .monutes-content .monutes-item .status i {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  .monutes-content .monutes-item .status span {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    display: block;
  }
}

@media (min-width: 1024px) {
  .monutes .table {
    padding: 15px;
  }
  .pool-item .pool-content ul li a i {
    font-size: 18px;
  }
  .pool-item {
    width: 48%;
    margin: 0 1% 20px;
  }

  .pool-item .pool-content {
    height: 370px;
    padding: 30px 20px 10px;
  }

  .pool-item .pool-content p {
    font-size: 20px;
    line-height: 24px;
  }

  .pool-item .pool-content .balance span {
    font-size: 18px;
    line-height: 22px;
  }

  .pool-item .pool-content .balance h3 {
    font-size: 46px;
    line-height: 58px;
  }

  .pool-item .pool-content .balance h3 img {
    width: 45px;
  }

  .pool-item .pool-content ul {
    padding: 20px 0 16px;
  }

  .pool-item .pool-content ul li a {
    font-size: 18px;
    line-height: 20px;
    padding-left: 27px;
  }
  .pool-content {
    height: 370px;
    padding: 30px 20px 10px;
  }

  .pool-content p {
    font-size: 20px;
    line-height: 24px;
  }

  .pool-content .balance span {
    font-size: 18px;
    line-height: 22px;
  }

  .pool-content .balance h3 {
    font-size: 46px;
    line-height: 58px;
  }

  .pool-content .balance h3 img {
    width: 45px;
  }

  .pool-content ul {
    padding: 20px 0 16px;
  }

  .pool-content ul li a {
    font-size: 18px;
    line-height: 20px;
    padding-left: 27px;
  }
  .pool-content ul li a i {
    font-size: 18px;
  }
  .monutes-content .monutes-item {
    margin-bottom: 10px;
    padding: 0 5px 12px;
  }

  .monutes-content .monutes-item .lvl {
    width: 7%;
  }

  .monutes-content .monutes-item .coin {
    width: 19%;
  }

  .monutes-content .monutes-item .coin p {
    font-size: 16px;
    line-height: 18px;
  }

  .monutes-content .monutes-item .coin p img {
    width: 22px;
    margin-right: 10px;
  }

  .monutes-content .monutes-item .contributed,
  .monutes-content .monutes-item .deposit {
    font-size: 16px;
    line-height: 18px;
    width: 18%;
  }

  .monutes-content .monutes-item .profit {
    font-size: 16px;
    width: 23%;
  }

  .monutes-content .monutes-item .profit strong {
    font-size: 12px;
  }

  .monutes-content .monutes-item .status {
    width: 10%;
  }

  .monutes-table {
    padding: 0 5px 10px;
  }

  .monutes-table ul li:nth-child(1) {
    width: 7%;
  }

  .monutes-table ul li:nth-child(2) {
    width: 19%;
  }

  .monutes-table ul li:nth-child(3),
  .monutes-table ul li:nth-child(4) {
    width: 18%;
  }

  .monutes-table ul li:nth-child(5) {
    width: 23%;
  }

  .monutes-table ul li:nth-child(6) {
    width: 10%;
  }
}

@media (min-width: 1320px) {
  .monutes {
    margin: 0 5px;
    padding-top: 0px;
  }
  .monutes-title ul li a {
    font-size: 20px;
    line-height: 26px;
    padding: 16px 10px;
  }

  .monutes-title ul li a i {
    font-size: 26px;
    margin-right: 8px;
  }
  .pool-item .pool-content {
    padding: 30px 30px 10px;
  }

  .pool-item .pool-content ul {
    padding: 26px 0 16px;
  }
  .pool-content {
    padding: 30px 30px 10px;
  }

  .pool-content ul {
    padding: 26px 0 16px;
  }
  .monutes-table ul li:nth-child(1) {
    width: 7%;
  }

  .monutes-table ul li:nth-child(2) {
    width: 18%;
  }

  .monutes-table ul li:nth-child(3),
  .monutes-table ul li:nth-child(4) {
    width: 17%;
  }

  .monutes-table ul li:nth-child(5) {
    width: 22%;
  }

  .monutes-table ul li:nth-child(6) {
    width: 18%;
  }
  .monutes-content .monutes-item .lvl {
    width: 7%;
  }

  .monutes-content .monutes-item .coin {
    width: 18%;
  }

  .monutes-content .monutes-item .contributed,
  .monutes-content .monutes-item .deposit {
    width: 17%;
  }

  .monutes-content .monutes-item .profit {
    width: 22%;
  }

  .monutes-content .monutes-item .status {
    width: 18%;
  }
}

@media (min-width: 1600px) {
  .monutes {
    margin: 0 10px;
    padding-top: 0px;
  }
  .monutes-title {
    margin: 0 -10px;
  }

  .monutes-title ul li a {
    font-size: 20px;
    line-height: 26px;
    padding: 25px 10px;
  }

  .monutes-title ul li a i {
    font-size: 28px;
  }
  .pool-item {
    width: 47%;
    margin: 0 1.5% 40px;
  }

  .pool-item .pool-content {
    height: 445px;
    padding: 30px 40px 10px;
  }
  .monutes-table {
    padding: 0 10px 15px;
  }
  .pool-content {
    height: 445px;
    padding: 30px 40px 10px;
  }
  .monutes-table ul li {
    font-size: 14px;
    line-height: 16px;
  }

  .monutes-table ul li:nth-child(1) {
    width: 10.4%;
  }

  .monutes-table ul li:nth-child(2) {
    width: 18%;
  }

  .monutes-table ul li:nth-child(3) {
    width: 17.6%;
  }

  .monutes-table ul li:nth-child(4) {
    width: 16%;
  }

  .monutes-table ul li:nth-child(5) {
    width: 19.5%;
  }

  .monutes-table ul li:nth-child(6) {
    width: 18.5%;
  }
  .monutes-content .monutes-item .coin p,
  .monutes-content .monutes-item .status span {
    font-size: 18px;
    line-height: 20px;
  }
  .monutes-content .monutes-item .coin p img {
    width: 26px;
    margin-right: 6px;
  }
  .monutes-content .monutes-item .lvl {
    font-size: 18px;
    width: 10.4%;
  }

  .monutes-content .monutes-item .coin {
    width: 18%;
  }

  .monutes-content .monutes-item .contributed {
    font-size: 18px;
    line-height: 20px;
    width: 17.6%;
  }

  .monutes-content .monutes-item .deposit {
    font-size: 18px;
    line-height: 20px;
    width: 16%;
  }

  .monutes-content .monutes-item .profit {
    font-size: 18px;
    width: 19.5%;
  }

  .monutes-content .monutes-item .profit strong {
    font-size: 14px;
  }

  .monutes-content .monutes-item .status {
    width: 18.5%;
  }
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .profit {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  width: 87%;
  margin-top: 10px;
  padding: 6px 10px;
  text-align: center;
  color: #7036b6;
  border-radius: 15px;
  background: #f1ebf8;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .profit strong {
  font-weight: 700;
  color: #9999a5;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .status {
  width: 13%;
  margin-top: 28px;
  text-align: center;
}

.liquidity-pool .monutes .table .monutes-content .monutes-item .status i {
  font-size: 18px;
}
@media (min-width: 768px) {
  .liquidity-pool .monutes .table .monutes-table ul {
    display: flex;
  }
  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin i,
  .liquidity-pool .monutes .table .monutes-content .monutes-item .contributed i,
  .liquidity-pool .monutes .table .monutes-content .monutes-item .deposit i,
  .liquidity-pool .monutes .table .monutes-content .monutes-item .profit i {
    display: none;
  }
  .liquidity-pool .monutes .monutes-title ul li a i {
    font-size: 20px;
  }
  .liquidity-pool .info {
    margin-bottom: 21px;
    padding: 20px 28px 17px 18px;
  }

  .liquidity-pool .info p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 39px;
    color: #2b1a4f;
  }

  .liquidity-pool .info p i {
    font-size: 30px;
    top: 6px;
    left: -1px;
  }

  .liquidity-pool .info p strong {
    font-weight: 600;
  }

  .liquidity-pool .info p a {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: block;
    margin-top: 5px;
  }

  .liquidity-pool .info .close-info {
    top: 13px;
    right: 13px;
  }

  .liquidity-pool .pool {
    display: flex;
    margin: 0 -1.5%;
    flex-wrap: wrap;
    align-items: center;
  }

  .liquidity-pool .pool .pool-item {
    width: 47%;
    margin: 0 1.5% 20px;
  }

  .liquidity-pool .pool .pool-item .pool-content {
    height: 350px;
    padding: 25px 20px 10px;
  }

  .liquidity-pool .monutes .liquidity-pool .pool .pool-item .pool-content p i {
    font-size: 20px;
    margin-left: 8px;
  }

  .liquidity-pool .pool .pool-item .pool-content .balance span {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  .liquidity-pool .pool .pool-item .pool-content .balance h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
  }

  .liquidity-pool .pool .pool-item .pool-content .balance h3 img {
    width: 38px;
  }

  .liquidity-pool .pool .pool-item .pool-content .balance i {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    opacity: 0.6;
    color: #f8f5fb;
  }

  .liquidity-pool .monutes .monutes-title h4 i {
    font-size: 28px;
    margin-right: 8px;
    color: #7036b6;
  }

  .liquidity-pool .monutes .monutes-title ul li a {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 10px;
  }

  .liquidity-pool .monutes .table {
    padding: 15px 9px;
  }

  .liquidity-pool .monutes .table .monutes-table {
    display: block;
    margin-bottom: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(136, 136, 136, 0.15);
  }

  .liquidity-pool .monutes .table .monutes-table ul li {
    font-size: 12px;
    line-height: 15px;
    color: #9999a5;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(1) {
    width: 8%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(2) {
    width: 15%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(3) {
    width: 18%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(4) {
    width: 15%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(5) {
    width: 23%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(6) {
    width: 21%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item {
    margin-bottom: 12px;
    padding-bottom: 12px;
    align-items: center;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .lvl {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    display: block;
    width: 8%;
    color: #7036b6;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin {
    width: 15%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin p {
    font-weight: 500;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin p img {
    width: 20px;
    margin-right: 2px;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .contributed {
    font-weight: 600;
    width: 18%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .deposit {
    font-weight: 500;
    width: 15%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .profit {
    font-size: 14px;
    width: 23%;
    margin: 0;
    padding: 0;
    text-align: left;
    background: 0 0;
  }

  .liquidity-pool
    .monutes
    .table
    .monutes-content
    .monutes-item
    .profit
    strong {
    font-weight: 400;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .status {
    position: relative;
    width: 21%;
    margin: 0;
    padding: 0 0 0 22px;
    text-align: left;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .status i {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .status span {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    display: block;
  }
}
@media (min-width: 1024px) {
  .liquidity-pool .pool .pool-item .pool-content ul li a i {
    font-size: 18px;
  }
  .liquidity-pool .info p a {
    display: inline-block;
    margin-top: 0;
    margin-left: 3px;
  }

  .liquidity-pool .pool {
    margin: 0 -1%;
  }

  .liquidity-pool .pool .pool-item {
    width: 48%;
    margin: 0 1% 20px;
  }

  .liquidity-pool .pool .pool-item .pool-content {
    height: 370px;
    padding: 30px 20px 10px;
  }

  .liquidity-pool .pool .pool-item .pool-content p {
    font-size: 20px;
    line-height: 24px;
  }

  .liquidity-pool .pool .pool-item .pool-content .balance span {
    font-size: 18px;
    line-height: 22px;
  }

  .liquidity-pool .pool .pool-item .pool-content .balance h3 {
    font-size: 46px;
    line-height: 58px;
  }

  .liquidity-pool .pool .pool-item .pool-content .balance h3 img {
    width: 45px;
  }

  .liquidity-pool .pool .pool-item .pool-content ul {
    padding: 20px 0 16px;
  }

  .liquidity-pool .pool .pool-item .pool-content ul li a {
    font-size: 18px;
    line-height: 20px;
    padding-left: 27px;
  }

  .liquidity-pool .monutes .table {
    padding: 15px;
  }

  .liquidity-pool .monutes .table .monutes-table {
    padding: 0 5px 10px;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(1) {
    width: 7%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(2) {
    width: 19%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(3),
  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(4) {
    width: 18%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(5) {
    width: 23%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(6) {
    width: 10%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item {
    margin-bottom: 10px;
    padding: 0 5px 12px;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .lvl {
    width: 7%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin {
    width: 19%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin p {
    font-size: 16px;
    line-height: 18px;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin p img {
    width: 22px;
    margin-right: 10px;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .contributed,
  .liquidity-pool .monutes .table .monutes-content .monutes-item .deposit {
    font-size: 16px;
    line-height: 18px;
    width: 18%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .profit {
    font-size: 16px;
    width: 23%;
  }

  .liquidity-pool
    .monutes
    .table
    .monutes-content
    .monutes-item
    .profit
    strong {
    font-size: 12px;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .status {
    width: 10%;
  }
}
@media (min-width: 1320px) {
  .platforma
    .platforma-content
    .platforma-nav
    nav
    ul
    li[data-tabs="liquidity-pool"] {
    background: #f1ebf8;
  }

  .platforma
    .platforma-content
    .platforma-nav
    nav
    ul
    li.active[data-tabs="liquidity-pool"],
  .platforma
    .platforma-content
    .platforma-nav
    nav
    ul
    li[data-tabs="liquidity-pool"]:hover {
    background: #7036b6;
  }

  .platforma
    .platforma-content
    .platforma-nav
    nav
    ul
    li.active[data-tabs="liquidity-pool"]
    a,
  .platforma
    .platforma-content
    .platforma-nav
    nav
    ul
    li.active[data-tabs="liquidity-pool"]
    a
    i,
  .platforma
    .platforma-content
    .platforma-nav
    nav
    ul
    li[data-tabs="liquidity-pool"]:hover
    a,
  .platforma
    .platforma-content
    .platforma-nav
    nav
    ul
    li[data-tabs="liquidity-pool"]:hover
    a
    i {
    color: #fff;
  }

  .platforma
    .platforma-content
    .platforma-nav
    nav
    ul
    li[data-tabs="liquidity-pool"]::after {
    display: none;
  }
  .liquidity-pool .info {
    margin: 0 5px 21px;
  }

  .liquidity-pool .pool {
    margin: 0 -0.5%;
  }

  .liquidity-pool .pool .pool-item .pool-content {
    padding: 30px 30px 10px;
  }

  .liquidity-pool .pool .pool-item .pool-content ul {
    padding: 26px 0 16px;
  }

  .liquidity-pool .monutes {
    margin: 0 5px;
  }

  .liquidity-pool .monutes .monutes-title ul li a {
    font-size: 20px;
    line-height: 26px;
    padding: 16px 10px;
  }

  .liquidity-pool .monutes .monutes-title ul li a i {
    font-size: 26px;
    margin-right: 8px;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(1) {
    width: 7%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(2) {
    width: 18%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(3),
  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(4) {
    width: 17%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(5) {
    width: 22%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(6) {
    width: 18%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .lvl {
    width: 7%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin {
    width: 18%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .contributed,
  .liquidity-pool .monutes .table .monutes-content .monutes-item .deposit {
    width: 17%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .profit {
    width: 22%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .status {
    width: 18%;
  }
}
@media (min-width: 1600px) {
  .popup-content .close-popup {
    top: 1px;
  }

  .popup-content .close-popup i {
    font-size: 20px;
  }
  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin p,
  .liquidity-pool .monutes .table .monutes-content .monutes-item .status span {
    font-size: 18px;
    line-height: 20px;
  }
  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin p img {
    width: 26px;
    margin-right: 6px;
  }
  .liquidity-pool .info {
    margin: 0 10px 41px;
    padding: 21px 60px 22px 18px;
  }

  .liquidity-pool .info p {
    font-size: 18px;
    line-height: 26px;
  }

  .liquidity-pool .info .close-info {
    right: 16px;
  }

  .liquidity-pool .pool {
    margin: 0 -1%;
  }

  .liquidity-pool .pool .pool-item {
    width: 47%;
    margin: 0 1.5% 40px;
  }

  .liquidity-pool .pool .pool-item .pool-content {
    height: 445px;
    padding: 30px 40px 10px;
  }

  .liquidity-pool .monutes {
    margin: 0 10px;
  }

  .liquidity-pool .monutes .monutes-title {
    margin: 0 -10px;
  }

  .liquidity-pool .monutes .monutes-title ul li a {
    font-size: 20px;
    line-height: 26px;
    padding: 25px 10px;
  }

  .liquidity-pool .monutes .monutes-title ul li a i {
    font-size: 28px;
  }

  .liquidity-pool .monutes .table {
    padding: 15px 23px;
  }

  .liquidity-pool .monutes .table .monutes-table {
    padding: 0 10px 15px;
  }

  .liquidity-pool .monutes .table .monutes-table ul li {
    font-size: 14px;
    line-height: 16px;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(1) {
    width: 10.4%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(2) {
    width: 18%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(3) {
    width: 17.6%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(4) {
    width: 16%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(5) {
    width: 19.5%;
  }

  .liquidity-pool .monutes .table .monutes-table ul li:nth-child(6) {
    width: 18.5%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .lvl {
    font-size: 18px;
    width: 10.4%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .coin {
    width: 18%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .contributed {
    font-size: 18px;
    line-height: 20px;
    width: 17.6%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .deposit {
    font-size: 18px;
    line-height: 20px;
    width: 16%;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .profit {
    font-size: 18px;
    width: 19.5%;
  }

  .liquidity-pool
    .monutes
    .table
    .monutes-content
    .monutes-item
    .profit
    strong {
    font-size: 14px;
  }

  .liquidity-pool .monutes .table .monutes-content .monutes-item .status {
    width: 18.5%;
  }
}

#help_video1 {
  background: url("../images/help-img1.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#help_video2 {
  background: url("../images/help-img2.jpg") no-repeat 50% 50%;
  background-size: cover;
}

.help-content .help-items {
  margin-bottom: 40px;
}

.help-content .help-items .help-item {
  margin-bottom: 30px;
}

.help-content .help-items .help-item:last-child {
  margin-bottom: 0;
}

.help-content .help-items .help-item .video {
  position: relative;
  overflow: hidden;
  margin-bottom: 13px;
  cursor: pointer;
  border-radius: 10px;
}

.help-content .help-items .help-item .video iframe {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
}

.help-content .help-items .help-item .video .text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
}

.help-content .help-items .help-item .video .text h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 9px;
  color: #fff;
}

.help-content .help-items .help-item .video .text span {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  display: flex;
  opacity: 0.6;
  color: #fff;
  align-items: center;
}

.help-content .help-items .help-item .video .text span i {
  font-size: 16px;
  display: inline-block;
  margin-right: 6px;
  color: #fff;
}

.help-content .help-items .help-item .download a {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  display: flex;
  width: 100%;
  height: 52px;
  margin-bottom: 10px;
  transition: all 0.3s;
  color: #7036b6;
  border: 1px solid #7036b6;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.help-content .help-items .help-item .download a:hover {
  color: #fff;
  background: #7036b6;
}

.help-content .help-items .help-item .download a:hover i {
  color: #fff;
}

.help-content .help-items .help-item .download a i {
  font-size: 15px;
  display: inline-block;
  margin-right: 5px;
  color: #7036b6;
}

.help-content .help-items .help-item .download p {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  padding: 0 25px;
  text-align: center;
  color: #2b1a4f;
}

.help-content .info-content {
  border-radius: 10px;
  background: rgba(112, 54, 182, 0.1);
}

.help-content .info-content p {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 22px 25px 20px;
  text-align: center;
  color: #2b1a4f;
}

.help-content .info-content p i {
  font-size: 25px;
  display: block;
  margin: 0 auto;
  color: rgba(112, 54, 182, 0.4);
}

.help-content .info-content p img {
  display: inline-block;
  width: 25px;
  margin: 0 1px;
  transform: translate(0, 7px);
}

.help-content .info-content a {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  display: flex;
  width: 100%;
  height: 52px;
  color: #fff;
  border: 1px solid #cd2576;
  border-radius: 10px;
  background: #cd2576;
  align-items: center;
  justify-content: center;
}

.help-content .info-content a:hover {
  color: #cd2576;
  background: #fff;
}

.help-content .info-content a:hover i {
  color: #cd2576;
}

.help-content .info-content a i {
  font-size: 15px;
  display: inline-block;
  margin-right: 7px;
  color: #fff;
}

@media (min-width: 768px) {
  .help-content .help-items {
    margin-bottom: 60px;
  }

  .help-content .help-items .help-item {
    margin-bottom: 40px;
  }

  .help-content .help-items .help-item .video {
    margin-bottom: 15px;
    border-radius: 20px;
  }

  .help-content .help-items .help-item .video .text {
    padding: 20px 40px 40px;
  }

  .help-content .help-items .help-item .video .text h3 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 12px;
  }

  .help-content .help-items .help-item .video .text span {
    font-size: 18px;
    line-height: 22px;
  }

  .help-content .help-items .help-item .video .text span i {
    font-size: 24px;
    margin-right: 8px;
  }

  .help-content .help-items .help-item .download {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .help-content .help-items .help-item .download a {
    font-size: 16px;
    width: 48.5%;
    height: 60px;
    margin-bottom: 0;
  }

  .help-content .help-items .help-item .download p {
    font-size: 14px;
    line-height: 20px;
    width: 48.5%;
    padding: 0 60px 0 0;
    text-align: left;
  }

  .help-content .info-content {
    padding-bottom: 37px;
    border-radius: 20px;
  }

  .help-content .info-content p {
    font-size: 20px;
    line-height: 24px;
    display: flex;
    padding: 28px 25px 22px;
    align-items: center;
    justify-content: center;
  }

  .help-content .info-content p i {
    margin: 0 9px 0 0;
  }

  .help-content .info-content p img {
    width: 36px;
    margin: 0 2px;
    transform: translate(0, 0);
  }

  .help-content .info-content a {
    font-size: 16px;
    line-height: 18px;
    width: 354px;
    height: 58px;
    margin: 0 auto;
  }

  .help-content .info-content a i {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .help-content .help-items {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
  }

  .help-content .help-items .help-item {
    width: 49%;
    margin-bottom: 0;
  }

  .help-content .help-items .help-item .video {
    margin-bottom: 15px;
  }

  .help-content .help-items .help-item .video .text {
    padding: 20px 40px 30px;
  }

  .help-content .help-items .help-item .download {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .help-content .help-items .help-item .download a {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 16px;
  }

  .help-content .help-items .help-item .download p {
    width: 100%;
    padding: 0 80px;
    text-align: center;
  }

  .help-content .info-content {
    display: flex;
    padding-bottom: 0;
    align-items: center;
    justify-content: space-between;
  }

  .help-content .info-content p {
    padding: 0 0 0 20px;
  }

  .help-content .info-content p img {
    margin: 0 5px;
  }

  .help-content .info-content a {
    width: 31.5%;
    height: 74px;
    margin: 0;
    border-radius: 20px;
  }
}

@media (min-width: 1320px) {
  .help-content {
    margin: 0 5px;
  }
  .help-items .help-item .video {
    margin-bottom: 19px;
  }

  .help-items .help-item .download {
    justify-content: space-between;
  }

  .help-items .help-item .download a {
    width: 50.5%;
    margin: 0;
  }

  .help-items .help-item .download p {
    width: 46.3%;
    padding: 0;
    text-align: left;
  }

  .info-content a {
    width: 36.8%;
  }
}

@media (min-width: 1600px) {
  .help-content .help-items .help-item .video .text span i {
    font-size: 26px;
  }
  .help-content .help-items .help-item .video .text span {
    font-size: 20px;
    line-height: 24px;
  }
  .help-content {
    margin: 0 10px;
  }
  .help-content .help-items {
    margin-bottom: 60px;
  }

  .help-content .help-items .help-item {
    width: 48.5%;
  }

  .help-content .help-items .help-item .video {
    margin-bottom: 24px;
  }

  .help-content .help-items .help-item .video .text h3 {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 16px;
  }

  .help-content .help-items .help-item .download a {
    font-size: 18px;
    width: 43.5%;
    height: 72px;
  }

  .help-content .help-items .help-item .download a i {
    font-size: 20px;
    margin-right: 8px;
  }

  .help-content .help-items .help-item .download p {
    font-size: 18px;
    line-height: 24px;
    width: 52.3%;
  }

  .help-content .info-content p {
    font-size: 24px;
    line-height: 26px;
    padding: 0 0 0 40px;
  }

  .help-content .info-content p img {
    margin: 0 9px;
  }

  .help-content .info-content a {
    font-size: 18px;
    width: 43.8%;
    height: 88px;
  }

  .help-content .info-content a i {
    font-size: 20px;
  }
}

.left-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  left: 14px;
  & > i {
    color: #fff;
    display: inline-block;
    font-size: 21px;
    opacity: 0.6;
    transition: all 0.5s;
  }
  &:hover > .info-window {
    display: block;
  }
  & > .info-window {
    background: #fff;
    border: 1px solid rgba(112, 54, 182, 0.5);
    border-radius: 10px;
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    color: #7036b6;
    display: none;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    padding: 12px;
    position: absolute;
    left: -9px;
    top: 40px;
    max-width: 400px;
    @media screen and (max-width: 450px) {
      max-width: 320px;
    }
    & a {
      overflow-wrap: break-word;
    }
    &:after {
      background-size: cover;
      content: "";
      height: 21px;
      position: absolute;
      /* right: 17px; */
      left: 17px;
      top: -16px;
      transform: rotate(90deg);
      width: 13px;
    }
  }
}

.popup-withdrawal {
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  display: none;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  .close {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
  .popup-content {
    top: 50%;
    left: 50%;
    width: 320px;
    position: absolute;
    border-radius: 25px;
    padding: 0 10px 5px;
    background: #ffffff;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(112, 54, 182, 0.1);
    .close-popup {
      top: 14px;
      right: 12px;
      z-index: 3;
      cursor: pointer;
      position: absolute;
      &:hover {
        i {
          transform: rotate(360deg);
        }
      }
      i {
        color: #fff;
        display: block;
        font-size: 18px;
        transition: all 0.5s;
      }
    }
    .title {
      margin: 0 -16px;
      padding: 15px 50px;
      text-align: center;
      border-radius: 15px;
      background: #cd2576;
      transform: translate(0, -1px);
      h4 {
        color: #fff;
        font-size: 16px;
        display: flex;
        font-weight: 600;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
          font-size: 14px;
          margin-right: 7px;
        }
      }
    }
    .withdraw-content {
      padding-top: 14px;
      ul {
        display: flex;
        border-radius: 15px;
        background: #fae9f1;
        li {
          width: 50%;
          height: 49px;
          cursor: pointer;
          display: flex;
          font-size: 16px;
          color: #2b1a4f;
          font-weight: 600;
          line-height: 26px;
          font-style: normal;
          text-align: center;
          align-items: center;
          border-radius: 15px;
          justify-content: center;
        }
        li.active {
          color: #fff;
          background: linear-gradient(180deg, #cd2576 0%, #8a164e 100%);
        }
      }
      .popup_tabs {
        display: none;
      }
      .popup_tabs.active {
        display: block;
      }
      .commission {
        display: flex;
        font-size: 12px;
        color: #9999a5;
        font-weight: 400;
        padding: 32px 20px;
        line-height: 20px;
        align-items: center;
        justify-content: space-between;
        div {
          text-align: center;
        }
        strong {
          display: block;
          font-weight: 400;
        }
      }
      p {
        font-size: 12px;
        color: #9999a5;
        line-height: 18px;
        text-align: center;
        font-weight: normal;
        margin-bottom: 5px;
      }
      a {
        width: 100%;
        height: 44px;
        border: none;
        font-size: 14px;
        display: flex;
        color: #7036b6;
        font-weight: 500;
        line-height: 17px;
        align-items: center;
        justify-content: center;
        background: transparent;
        border-top: 1px solid rgba(112, 54, 182, 0.1);
        i {
          display: block;
          font-size: 14px;
          color: #7036b6;
          margin-right: 6px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .popup-content {
      width: 467px;
      padding: 0 20px 12px;
      .close-popup {
        top: 2px;
        right: -47px;
      }
      .title {
        margin: 0 -30px;
        padding: 23px 10px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 18px;
            margin-right: 8px;
          }
        }
      }
      .withdraw-content {
        padding-top: 20px;
        ul {
          li {
            font-size: 18px;
          }
        }
        .commission {
          font-size: 16px;
          line-height: 24px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 5px;
        }
        a {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .popup-content {
      .close-popup {
        top: 1px;
        i {
          font-size: 20px;
        }
      }
      .title {
        padding: 24px 30px;
        h4 {
          font-size: 20px;
          line-height: 26px;
          i {
            font-size: 20px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
