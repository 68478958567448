.loading{
    top: 0;
    left: 0;
    z-index: 8;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: linear-gradient(0deg, rgba(112, 54, 182, 0.05), rgba(112, 54, 182, 0.05)), #FFFFFF;
    .loading-content{
        left: 50%;
        top: 53.5%;
        width: 200px;
        position: absolute;
        transform: translate(-50%, -50%);
        .loading-img{
            width: 92px;
            display: block;
            position: relative;
            margin: 0 auto 21px;
            svg{
                top: 50%;
                left: 50%;
                width: 102px;
                position: absolute;
                transform: translate(-50%, -50%);
            }
            img{
                width: 100%;
                display: flex;
            }
        }
        p{
            font-size: 14px;
            color: #9999A5;
            line-height: 21px;
            text-align: center;
            font-weight: normal;
        }
    }
    @media (min-width: 768px) {
        .loading-content {
            width: 300px;
            .loading-img {
                width: 176px;
                margin: 0 auto 31px;
                svg {
                    width: 196px;
                }
            }
            p{
                font-size: 18px;
                color: #9999A5;
                line-height: 27px;
                font-weight: normal;
            }
        }
    }
    @media (min-width: 1600px) {
        .loading-content {
            top: 51.5%;
        }
    }
}